import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const ForgetPassword = () => {

    const [forgotEmail, setForgotEmail] = useState("")

    const handleForgetEmail = (event) =>{

        setForgotEmail(event.target.value)
    }

    const navigate = useNavigate();

    const VerifyCode = () =>{

        navigate("/verifycode")
    }

  return (
    <>
      <section className="card-area margin-top-20px margin-bottom-55px">
        <div className="content container1">
          <div className="offer-container">
            <div className="row">
              <div className="sky-sub-title">
                Forgot Password - Verification Code
              </div>
              <div className="col-md-8 col-sm-12 col-lg-8">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <form method="" action="">
                   
                      <div className="form-group">
                        <label for="">Email / Mobile:</label>
                        <input
                          type="text"
                          id=""
                          name=""
                          value={forgotEmail}
                          className="form-control"
                          onChange={handleForgetEmail}
                        />
                      </div>
                      <button type="submit" className="btn" onClick={VerifyCode}>
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgetPassword;
