import React, { useState } from 'react'
import { Link, useNavigate } from "react-router-dom";

const VerificationCode = () => {

    const navigate = useNavigate();


    const  [email, setEmail] = useState("")
    const  [confirmOTP, setConfirmOTP] = useState("")

    const handleEmail =(event)=>{

      setEmail(event.target.value)
    }

    const handleConfirmOtp = (event) => {

      setConfirmOTP(event.target.value)
    }

    const navNewPassword = () =>{

      navigate("/newpassword")
  }


  return (
    <>
    
    <section className="card-area margin-top-20px margin-bottom-55px">
        <div className="content container1">
          <div className="offer-container">
            <div className="row">
              <div className="sky-sub-title">
                Verify Code
              </div>
              <div className="col-md-8 col-sm-12 col-lg-8">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <form method="" action="">
                      <input type="hidden" name="" value="" />{" "}
                      <div className="form-group">
                        <label for="">Enter Email:</label>
                        <input
                          type="email"
                          id=""
                          name="txtCode"
                          value={email}
                          className="form-control"
                          onChange={handleEmail}
                        />
                      </div>

                      <div className="form-group">
                        <label for="">Enter OTP</label>
                        <input
                          type="password"
                          id=""
                          name="txtConfirmPassword"
                          value={confirmOTP}
                          className="form-control"
                          onChange={handleConfirmOtp}
                        />
                      </div>
                      <button type="submit" className="btn" onClick={navNewPassword} >
                        Next
                      </button>
                      
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default VerificationCode