import React, { useState } from "react";

import { Link } from "react-router-dom";

const ProfilePage = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNo, setmobileNo] = useState("");
  const [EmailId, setEmailId] = useState("")
  

  const handleFirstName = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastName = (e) => {
    setLastName(e.target.value);
  };

  const handleMobile = (e) => {
    setmobileNo(e.target.value);
  };

  const handleEmailId = (e) => {
    setEmailId(e.target.value);
  };


  return (
    <>
      <section className="mt-[20px] mb-[55px]">
        <div
          className="container1"
          style={{
            boxShadow: "0 3px 30px 0 rgba(0 0 0 /10%)",
            backgroundColor: "#fff",
            padding: "10px 10px 30px 10px",
            borderRadius: "10px",
          }}
        >
          <div
            className="chip "
            style={{
              display: "inline-block",
              padding: "0 25px",
              height: "50px",
              fontSize: "16px",
              lineHeight: "50px",
              borderRadius: "25px",
              backgroundColor: "#f1f1f1",
              border: "1px solid #ccc",
              marginBottom: "10px",
            }}
          >
            <img
              className=""
              src="/images/user-icon-01.gif"
              alt=""
              width="96"
              height="96"
              style={{
                float: "left",
                margin: "0 10px 0 -25px",
                height: "49px",
                width: "50px",
                borderRadius: "50%",
                border: "1px solid #ccc",
              }}
            />
            Cust Name
          </div>

          <div className="  ">
            <LoginSideBar></LoginSideBar>

            <div
              style={{ minHeight: "360px", padding: "1px 16px" }}
              className="min-[601px]:ms-[200px] "
            >
              <div
                className=" "
                style={{
                  boxShadow: "0 3px 30px 0 rgb(0 0 0 /10%)",
                  backgroundColor: "#fff",
                  padding: "10px 10px 30px 10px",
                  borderRadius: "10px",
                  zIndex: "",
                }}
              >
                <div className="row">
                  <div
                    className="sky-sub-title"
                    style={{
                      fontFamily: "lato, sans-serif",
                      color: "#331a4f",
                      fontSize: "22px",
                      fontWeight: "bold",
                      visibility: "visible",
                      animationName: "fadeInDown",
                      marginLeft: "10px",
                    }}
                  >
                    My Profile
                  </div>
                  <div className="col-md-8 col-sm-12 col-lg-8">
                    <p
                      className="sky-sub-title-2"
                      style={{
                        fontFamily: "Lato, sans-serif",
                        color: "#ccc",
                        fontSize: "16px",
                        fontWeight: "bold",
                        visibility: "visible",
                        animationName: "fadeInDown",
                      }}
                    >
                      {" "}
                      Personal Details{" "}
                    </p>
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <div
                          className="input-group"
                          style={{ padding: "10px 0" }}
                        >
                          <span className="input-group-addon">
                            <i className="glyphicon glyphicon-user"></i>
                          </span>
                          <input
                            id="firstname"
                            type="text"
                            className="form-control "
                            name="txtFirstname"
                            value={firstName}
                            placeholder="FirstName"
                            onChange={handleFirstName}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div
                          className="input-group"
                          style={{ padding: "10px 0" }}
                        >
                          <span className="input-group-addon">
                            <i className="glyphicon glyphicon-user"></i>
                          </span>
                          <input
                            id="lastname"
                            type="text"
                            className="form-control "
                            name="txtLastname"
                            value={lastName}
                            placeholder="LastName"
                            onChange={handleLastName}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <div
                          className="input-group"
                          style={{ padding: "10px 0" }}
                        >
                          <span className="input-group-addon">
                            <i className="glyphicon glyphicon-phone"></i>
                          </span>
                          <input
                            id="mobile"
                            type="text"
                            className="form-control "
                            name="txtMobile"
                            value={mobileNo}
                            placeholder="Mobile No"
                            onChange={handleMobile}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div
                          className="input-group"
                          style={{ padding: "10px 0" }}
                        >
                          <span className="input-group-addon">
                            <i className="glyphicon glyphicon-envelope"></i>
                          </span>
                          <input
                            id="email"
                            type="text"
                            className="form-control "
                            name="txtEmail"
                            value={EmailId}
                            placeholder="Email Id"
                            onChange={handleEmailId}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3 col-sm-11 col-lg-3">
                    <div className="row">
                      <div className="col-md-12 col-sm-12">
                        <div
                          className=" mb-3 "
                          style={{ position: "relative", textAlign: "center" }}
                        >
                          <div
                            className=" edit-bt"
                            style={{
                              position: "absolute",
                              left: "0",
                              cursor: "pointer",
                              top: "0",
                              right: "0",
                              margin: "auto",
                              bottom: "0",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              background: "#000",
                              width: "30px",
                              height: "30px",
                              borderRadius: "50px",
                              color: "#fff",
                              opacity: "0.7",
                            }}
                          >
                            <label for="upload-photo" className="mb-0">
                              <i className="fa-solid fa-pen text-white"></i>
                            </label>
                            <input
                              type="file"
                              name="photo"
                              id="upload-photo"
                              style={{
                                opacity: "0",
                                position: "absolute",
                                zIndex: "-1",
                              }}
                            />
                          </div>

                          <div className="flex justify-center">
                            <img
                              src="/images/profile.jpg"
                              alt=""
                              className="rounded-full"
                              width="120"
                              height="120"
                              style={{}}
                            />
                          </div>

                          <p className="text-label"> Profile Photo </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const LoginSideBar = () => {
  const [openNav, setOpenNav] = useState(false);

  const LoginSideBar = [
    {
      id  : 1,
      icon: "fa-solid fa-table-list",
      name: "My Booking",
      link: "/mybookings",
    },

    {
      id  : 2,
      icon: "fa-solid fa-award",
      name: "Elite Member",
      link: "/elitemember",
    },

    {
      id  : 3,
      icon: "fa-solid fa-user",
      name: "My Profile",
      link: "/profile",
    },

    {
      id  : 4,
      icon: "fa-solid fa-right-from-bracket",
      name: "Logout",
      link: "/selectseat",
    },
  ];

  return (
    <>
      <div className="sidebar-div">
        <div
          className=" displayblockk w-[200px]  bg-[#f1f1f1] max-[600px]:hidden  min-[601px]:block  min-[601px]:absolute "
          style={{
            height: "auto",
            overflow: "auto",
            margin: "0",
            padding: "0",
          }}
        >
          {LoginSideBar.map((val, index) => (
            <Link 
              to={val.link}
              style={{
                display: "block",
                color: "#331a4f",
                padding: "16px",
                fontSize: "16px",
              }}
            >
              <i className={`ms-[10px] me-[10px] ${val.icon}`}></i>
              {val.name}
            </Link>
          ))}
        </div>

        <div
          className=" displayblockk mb-10    bg-[#f1f1f1] max-[600px]:block  min-[601px]:hidden "
          style={{ padding: "16px" }}
        >
          <div
            className="flex   justify-end"
            onClick={() => setOpenNav(!openNav)}
          >
            <span className="flex justify-center items-center  w-[15px]">
              ☰
            </span>
          </div>

          {openNav ? (
            <div className="">
              {LoginSideBar.map((val, index) => (
                <Link
                  className=""
                  to={val.link}
                  style={{
                    display: "block",
                    color: "#331a4f",
                    padding: "16px",
                    fontSize: "16px",
                  }}
                >
                  <i className={`ms-[10px] me-[10px] ${val.icon}`}></i>

                  {val.name}
                </Link>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ProfilePage;

export { LoginSideBar };
