import React from 'react'

const Banner = () => {
  return (
    
    <>


    <div className="banner-1 " style={{background:"url(/images/pencilsketch.png)",backgroundRepeat:"no-repeat",minHeight:"400px",width:"100%",backgroundSize:"cover",WebkitBackgroundSize:"cover" ,borderBottom:"3px solid #ccc"}}>
		<div className="container1">
			<h1 className="wow zoomIn animated animated animated" data-wow-delay=".5s" style={{visibility:"visible",animationDelay:"0.5s",fontWeight:"bold",animationName:"zoomIn",fontStyle:"oblique",fontSize:"40px",marginBottom:"40px",padding:"2.5em 0 0",textAlign:"center",color:"navy"}} > BookUrSelf - Get Best Deals On Your Finger Tips<br/>
                <a href="index" className="margin-top-20px">
                    <button type="button" id="btnMoveToTravel" className="btn" style={{display:"inline-block",color:"navy", fontSize:"13px",fontWeight:"700",letterSpacing:".5px",textTransform:"uppercase",borderRadius:"5px",border:"2px solid transparent",transition:"all .35s ease",backgroundColor:"plum"}}>
                        Book Your Ticket Now!!!
                    </button>
                </a>
            </h1>
		</div>
	</div>

    </>
  )
}

export default Banner