import React, { useState } from 'react'

const NewPassword = () => {

  const [newPassword, SetNewPassword] = useState("")

  const [confirmPassword, SetConfirmPassword] = useState("")

  const handleNewPassword = (event) => {

    SetNewPassword(event.target.value)
  }

  const handleConfirmPassword = (event) => {

    SetConfirmPassword(event.target.value)
  }

  return (

    <>

<section className="card-area margin-top-20px margin-bottom-55px">
        <div className="content container1">
          <div className="offer-container">
            <div className="row">
              <div className="sky-sub-title">
                Create New Password
              </div>
              <div className="col-md-8 col-sm-12 col-lg-8">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <form method="" action="">
                      
                      <div className="form-group">
                        <label for="">New password:</label>
                        <input
                          type="password"
                          id=""
                          name="txtPassword"
                          value={newPassword}
                          className="form-control"
                          onChange={handleNewPassword}
                        />
                      </div>

                      <div className="form-group">
                        <label for="">Confirm password:</label>
                        <input
                          type="password"
                          id=""
                          name="txtConfirmPassword"
                          value={confirmPassword}
                          className="form-control"
                          onChange={handleConfirmPassword}
                        />
                      </div>
                      <button type="submit" className="btn">
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    
    </>
  )
}

export default NewPassword