import React from 'react'
import { LoginSideBar } from './ProfilePage'

const EliteMemberPage = () => {
  return (
    <>
    
    <section className="mt-[20px] mb-[55px]">
        <div
          className="container1"
          style={{
            boxShadow: "0 3px 30px 0 rgba(0 0 0 /10%)",
            backgroundColor: "#fff",
            padding: "10px 10px 30px 10px",
            borderRadius: "10px",
          }}
        >
          <div
            className="chip "
            style={{
              display: "inline-block",
              padding: "0 25px",
              height: "50px",
              fontSize: "16px",
              lineHeight: "50px",
              borderRadius: "25px",
              backgroundColor: "#f1f1f1",
              border: "1px solid #ccc",
              marginBottom: "10px",
            }}
          >
            <img
              className=""
              src="/images/user-icon-01.gif"
              alt="User"
              width="96"
              height="96"
              style={{
                float: "left",
                margin: "0 10px 0 -25px",
                height: "49px",
                width: "50px",
                borderRadius: "50%",
                border: "1px solid #ccc",
              }}
            />
            Cust Name
          </div>



          <div className="  ">

        <LoginSideBar></LoginSideBar>



            <div style={{ minHeight: "360px", padding: "1px 16px" }} className="min-[601px]:ms-[200px] ">
       
      
            <div className="offer-container " style={{boxShadow:"0 3px 30px 0 rgb(0 0 0 / 10%)",backgroundColor:"#fff", padding:"10px 10px 30px 10px",borderRadius:"10px",}}>
                    <div className="row">
                            <div className="sky-sub-title" style={{fontFamily:"Lato, sans-serif",color:"#331a4f", fontSize:"22px", fontWeight:"bold", visibility:"visible", marginLeft:"10px" , animationName:"fadeInDown"}}>Elite Member</div>
                            <div className="col-md-12 col-sm-12 col-lg-12">
                                <div className="text-center" style={{textAlign:"center"}}>
                                    <img className="img-responsive center-img prime-img" alt="" style={{width:"150px",height:"150px", display:"block", marginRight:"auto", marginLeft:"auto"}} src="/images/invite/member.png"/>

                                    <div className="prime-text" style={{marginBottom:"40px",paddingTop:"20px", paddingLeft:"0", fontSize:"16px",fontWeight:"600"}}>
                                        <span>
                                            Avail exclusive benefits by becoming a ELITE member at just 199.00 INR.
                                        </span>
                                    </div>

                                    <button className="btn" type="button" value="" id="">Pay - 199.00</button>


                                    <div className="prime-sub-text" style={{lineHeight:"18px", marginTop:"20px", marginBottom:"20px", textAlign:"center"}}>
                                        <span className="prime-label" style={{fontWeight:"700",fontSize:"13px", color:"#847f7f", lineHeight:"18px"}}>
                                            With our referral program you can earn assured wallet money anywhere between 30.00 to 55.00.<br/>
                                            The best part is you can utilize the entire wallet money for your booking.
                                        </span>
                                    </div>
                                </div>
                                
 
                            </div>
                    </div>

                                                   
                                           

                </div>


            </div>



          </div>
        </div>
      </section>

    </>
  )
}

export default EliteMemberPage