import React, { useState } from "react";
import ".//../Components css/Header.css";
import { Link } from "react-router-dom";
import SignIn from "./SignIn";

const Header = () => {
  const [state, setState] = useState(0);
  const [emailId, setEmailId] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [acnEmailId, setAcnEmailId] = useState("");
  const [acnPassword, setAcnPassword] = useState("");
  const [refferalCode, setRefferalCode] = useState("");

  const loginModal = (index) => {
    setState(index);
  };

  const closeModal = () => {
    setState(0);
  };

  const handleEmail = (event) => {
    setEmailId(event.target.value);
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleFirstName = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastName = (event) => {
    setlastName(event.target.value);
  };

  const handleMobileNo = (event) => {
    setMobileNo(event.target.value);
  };

  const handleAcnEmail = (event) => {
    setAcnEmailId(event.target.value);
  };

  const handleAcnPassword = (event) => {
    setAcnPassword(event.target.value);
  };

  const handleRefferalCode = (event) => {
    setRefferalCode(event.target.value);
  };

  return (
    <>
      {/* ////////////////////////////////////////////////////////////////////////////////////////////// */}
      <div
        className="header bg-[purple]  h-[83px] "
        style={{ padding: "10px 0 8px" }}
      >
        <div className=" container1  ">
          <div className="row flex  ">
            <div className=" left-s-header  ">
              <div className=" ">
                <Link to="/">
                  <img
                    src="/images/logo-sky1.png"
                    alt=""
                    className=" logo-header "
                  />
                </Link>
              </div>
            </div>

            <div className="right-s-header  p-[15px] flex justify-end ">
              <ul className="list-none flex gap-14">
                <li>
                  <Link to="/">
                    <i className="fa fa-home text-white text-[24px]"></i>
                  </Link>
                </li>

                <li className="header_links">
                  <Link
                    to="/offers"
                    className="text-[13px] font-[600] text-white"
                  >
                    Offer
                  </Link>
                </li>

                <li className="header_links">
                  <div onClick={() => loginModal(1)} className="flex gap-6">
                    <img
                      src="/images/user-icon-01.gif"
                      className="w-[20px] h-[20px]"
                      style={{ clipPath: "circle()" }}
                      alt=""
                    />

                    <span className=" text-[13px] font-[600] text-white">
                      Login
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* /////////////////////////////////////////////////////////  */}

      <div className={`${state === 0 ? "hidden" : "block"}`}>
        {/* /////////////////////////    Sign with your account page   ///////////////////////////////// */}

        <div
          className={`modal fade modal_div in bg-[rgba(0,0,0,0.5)] ${
            state === 1 ? "block" : "hidden"
          }`}
          id="myModal4"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content modal-info">
              <div className="modal-header">
                <button
                  type="button"
                  className="close border"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" onClick={closeModal}>
                    ×
                  </span>
                </button>
                <h3>Signin with your account </h3>
              </div>

              <div className="modal-body modal-spa">
                <div className="login-grids">
                  <div className="row">
                    <div className="col-md-5 col-sm-12">
                      <div className="login-left">
                        <h6
                          style={{
                            textAlign: "center",
                            color: "#c11d8e",
                            fontSize: "18px",
                            fontStyle: "italic",
                          }}
                        >
                          Sign in with
                        </h6>
                        <ul>
                          <li>
                            <Link to="/profile" className="goog" onClick={()=>loginModal(0)}>
                              <i
                                style={{
                                  background:
                                    "url('/images/social.png')  no-repeat -84px 0px",
                                  width: "21px",
                                  height: "21px",
                                  display: "inline-block",
                                  verticalAlign: "text-bottom",
                                  padding: "0 14px",
                                }}
                              ></i>
                              Sign in with Google
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-1 col-sm-12">
                      <div className="divider">
                        <span></span>
                        <span>or</span>
                        <span></span>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="login-right">
                        <form className="text-center " action="">
                          <h6
                            className="margin-bottom-10px "
                            style={{
                              textAlign: "center",
                              color: "#66184d",
                              fontSize: "18px",
                              fontStyle: "italic",
                            }}
                          >
                            Sign in to Bookurself
                          </h6>
                          <input
                            type="text"
                            name="txtEmailLogin"
                            className=""
                            placeholder="Enter Email ID"
                            style={{}}
                            value={emailId}
                            onChange={handleEmail}
                          />
                          <input
                            type="password"
                            name="txtPasswordLogin"
                            className=""
                            value={password}
                            placeholder="Enter Password"
                            style={{}}
                            onChange={handlePassword}
                          />
                          <button
                            className="btn"
                            type="submit"
                            value=""
                            style={{ width: "60%" }}
                          >
                            Signin
                          </button>
                          <div
                            className="errorBoxLogin"
                            style={{
                              color: "red",
                              marginTop: "20px",
                              marginBottom: "20px",
                            }}
                          ></div>
                          <div className="row">
                            <div className="col-sm-6 col-xs-6">
                              <Link
                                data-toggle="modal"
                                data-target="#signupModal"
                              >
                                <span
                                  onClick={() => loginModal(2)}
                                  style={{ fontSize: "15px", color: "#337ab7" }}
                                >
                                  Create an account
                                </span>
                              </Link>
                            </div>

                            <div className="col-sm-6 col-xs-6">
                              <h3>
                                <Link
                                  onClick={() => loginModal(0)}
                                  to="/forgotpassword"
                                  className="pull-right1"
                                  style={{
                                    marginLeft: "-1px",
                                    fontSize: "15px",
                                    marginTop: "2px",
                                    color: "#337ab7",
                                  }}
                                  id="forget_pswd"
                                >
                                  Forgot password
                                </Link>
                              </h3>
                            </div>
                          </div>
                          <div className="row"></div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <p>
                    By logging in you agree to our{" "}
                    <Link to="/terms" onClick={() => loginModal(0)}>Terms and Conditions</Link> and{" "}
                    <Link to="/privacy" onClick={() => loginModal(0)}>Privacy Policy</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* //////////////////////////////// Create Your Account Page /////////////////////////////////// */}

        <div
          className={`modal fade in  ${state === 2 ? "block" : "hidden"}`}
          id="signupModal"
          style={{ display: "block" }}
        >
          <div className="modal-dialog" role="document" style={{}}>
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" onClick={closeModal}>
                    ×
                  </span>
                </button>
                <h3>Create your account </h3>
              </div>
              <section>
                <div className="modal-body modal-spa">
                  <div className="login-grids">
                    <div className="login">
                      <div className="login-right">
                        <form>
                          <div className="row">
                            <div className="col-md-6 col-sm-12 ">
                              <input
                                type="text"
                                className=""
                                name="txtFirstName"
                                id="01"
                                value={firstName}
                                placeholder="First Name"
                                onChange={handleFirstName}
                              />
                            </div>
                            <div className="col-md-6 col-sm-12 ">
                              <input
                                type="text"
                                className=""
                                name="txtLastName"
                                id="02"
                                value={lastName}
                                placeholder="Last Name"
                                onChange={handleLastName}
                              />
                            </div>
                          </div>

                         

                          <div className="row">
                            <div className="col-md-6 col-sm-12 ">
                              <input
                                type="text"
                                className=""
                                name="txtMobile"
                                id="05"
                                value={mobileNo}
                                maxLength={10}
                                placeholder="Mobile Number"
                                onChange={handleMobileNo}
                              />
                            </div>
                            <div className="col-md-6 col-sm-12 ">
                              <input
                                type="email"
                                className=""
                                name="txtEmail"
                                id="06"
                                value={acnEmailId}
                                placeholder="Email ID"
                                onChange={handleAcnEmail}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6 col-sm-12 ">
                              <input
                                type="password"
                                className=""
                                name="txtPassword"
                                id="07"
                                value={acnPassword}
                                placeholder="Password"
                                onChange={handleAcnPassword}
                              />
                            </div>
                            <div className="col-md-6 col-sm-12 ">
                              <input
                                type="text"
                                className=""
                                name="txtRefferalCode"
                                id="08"
                                value={refferalCode}
                                maxLength={8}
                                placeholder="Refferal Code"
                                onChange={handleRefferalCode}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div
                              className="col-sm-6 col-xs-6"
                              style={{ fontSize: "14px" }}
                            >
                              Already have an account? Click here to
                              <a>
                                <span
                                  onClick={() => loginModal(1)}
                                  style={{ fontSize: "15px", color: "#337ab7" }}
                                >
                                  {" "}
                                  Signin
                                </span>
                              </a>
                            </div>

                            <button
                              className="btn "
                              type="submit"
                              value=""
                              style={{ width: "40%" }}
                            >
                              Signup
                            </button>
                          </div>
                        </form>
                      </div>
                      <p>
                        By logging in you agree to our{" "}
                        <a href="terms">Terms and Conditions</a> and{" "}
                        <a href="privacy">Privacy Policy</a>
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      {/* ////////////////////////  responsive navbar /////////////////////////////////////// */}

      <div className="sticky-navbar sky fixed bottom-0  p-[14px] max-[680px]:inline-block  w-[100%]  min-[681px]:hidden bg-[#331a4e]" style={{zIndex:"500"}}>
        <ul className="list-none flex justify-evenly">
          <li>
            <Link to="/">
              <i className="fa fa-home text-white text-[24px]"></i>
            </Link>
          </li>

          <li className="">
            <Link to="/offers" className="text-[16px]  text-white">
              offers
            </Link>
          </li>

          <li>
            <Link className="flex gap-4" onClick={() => loginModal(1)}>
              <img
                src="/images/user-icon-01.gif"
                className="w-[25px] h-[25px]"
                style={{ clipPath: "circle()" }}
                alt=""
              />

              <span className=" text-[16px] text-white">Login</span>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Header;
