import React from 'react'
import { Link } from "react-router-dom";


const StickyNavbar = () => {
  return (
    <>
    
    {/* <div className="sticky-navbar sky fixed bottom-0 z-200 p-[14px] max-[680px]:inline-block  w-[100%]  min-[681px]:hidden bg-[#331a4e]" >


    <ul className="list-none flex justify-evenly">

<li>
  <Link to="/">
  <i className="fa fa-home text-white text-[24px]"></i>
  </Link>
</li>

<li className="">

  <Link to="/offers" className="text-[16px]  text-white">
  offers
  </Link>

</li>

<li>
  <Link  className="flex gap-4">

  <img src="/images/user-icon-01.gif" className="w-[25px] h-[25px]" style={{clipPath:"circle()"}} alt="" />
   
   <span className=" text-[16px] text-white">Login</span>

  </Link>
</li>

</ul>


    </div> */}

    </>
  )
}

export default StickyNavbar