import Banner from '../Components/Banner'
import { Link } from 'react-router-dom'
import './/../Master css/MasterOffer.css'
import { useSwiper } from 'swiper/react';
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaLongArrowAltLeft } from "react-icons/fa";


import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide, loop } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/bundle';



// import required modules
import { Pagination, Autoplay, Navigation, } from 'swiper/modules';




const MasterOffers = () => {





  const swiper = useSwiper();

  return (
    <>

      <Banner className="" />
   

       <SliderBk/>   


      {/* <div className='offer_slide_div mt-[100px] mb-[100px]' style={{border:"1px solid red"}}> */}


      
      {/* </div> */}



    </>
  )
}


const SliderBk =()=>{
  return (
    <>
    
    <h3 className="  text-center  mt-[10px] " style={{ fontFamily: "Lato, sans-serif", fontSize: "30px", color: "rgb(192, 24, 150)", fontWeight: "bold" }}>Amazing Offers</h3>

<div className='container1 mt-[20px] mb-[100px] offer_container' >



  <div style={{ border: "" }}>


    <Swiper
      style={{

      }}
      slidesPerView={1}
      spaceBetween={10}
      loop={true}
      autoplay={true}
      pagination={{ el: '.swiper-pagination', clickable: true }}
      navigation={{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
        clickable: true,

      }}
      breakpoints={{

        // 480: {
        //   slidesPerView: 2,
        // },

        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 50,
        },
      }}
      modules={[Pagination, Autoplay, Navigation]}
      className="swiper_container"
    >

      <SwiperSlide className='swiper_slide'>

        <div>
          <img src="/img/offer/image-1.png" alt="" />
        </div>

        <div style={{ display: "flex", justifyContent: "space-between", padding: "5px 5px" }}>

          <div className='' style={{ padding: "" }}>
            <span className='offer_code'>CODE :</span>
            <span className='offer_name'> ONBUS</span>
          </div>

          <div>

            <Link to="/onbusoffer" className='offer_details'>
              View Details
            </Link>
          </div>
        </div>
      </SwiperSlide>



      <SwiperSlide className='swiper_slide'>

        <div>
          <img src="/img/offer/image-2.png" alt="" />
        </div>

        <div style={{ display: "flex", justifyContent: "space-between", padding: "5px 5px" }}>

          <div className='' style={{ padding: "" }}>
            <span className='offer_code'>CODE :</span>
            <span className='offer_name'> HOLYBUS</span>
          </div>

          <div>

            <Link to="/onbusoffer" className='offer_details'>
              View Details
            </Link>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide className='swiper_slide'>

        <div>
          <img src="/img/offer/image-3.png" alt="" />
        </div>

        <div style={{ display: "flex", justifyContent: "space-between", padding: "5px 5px" }}>

          <div className='' style={{ padding: "" }}>
            <span className='offer_code'>CODE :</span>
            <span className='offer_name'> FIRSTBUS</span>
          </div>

          <div>

            <Link to="/onbusoffer" className='offer_details'>
              View Details
            </Link>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide className='swiper_slide'>

        <div>
          <img src="/img/offer/image-1.png" alt="" />
        </div>

        <div style={{ display: "flex", justifyContent: "space-between", padding: "5px 5px" }}>

          <div className='' style={{ padding: "" }}>
            <span className='offer_code'>CODE :</span>
            <span className='offer_name'> ONBUS</span>
          </div>

          <div>

            <Link to="/onbusoffer" className='offer_details'>
              View Details
            </Link>
          </div>
        </div>
      </SwiperSlide>



      <SwiperSlide className='swiper_slide'>

        <div>
          <img src="/img/offer/image-2.png" alt="" />
        </div>

        <div style={{ display: "flex", justifyContent: "space-between", padding: "5px 5px" }}>

          <div className='' style={{ padding: "" }}>
            <span className='offer_code'>CODE :</span>
            <span className='offer_name'> HOLYBUS</span>
          </div>

          <div>

            <Link to="/onbusoffer" className='offer_details'>
              View Details
            </Link>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide className='swiper_slide'>

        <div>
          <img src="/img/offer/image-3.png" alt="" />
        </div>

        <div style={{ display: "flex", justifyContent: "space-between", padding: "5px 5px" }}>

          <div className='' style={{ padding: "" }}>
            <span className='offer_code'>CODE :</span>
            <span className='offer_name'> FIRSTBUS</span>
          </div>

          <div>

            <Link to="/onbusoffer" className='offer_details'>
              View Details
            </Link>
          </div>
        </div>
      </SwiperSlide>



    </Swiper>

  </div>


  <div style={{ display: "flex", justifyContent: "center" }}>

    <div className='flex  mt-[15px]  h-[50px]' style={{ border: "", alignItems: "center", gap: "px" }} >



      <div className="flex  slider-controler" style={{ gap: "" }}>

        <div st>

          <div className="swiper-button-prev slider-arrow" style={{ border: "", }}></div>

          <div style={{ border: "" }}><FaLongArrowAltLeft style={{ width: "25px", height: "25px", color: "white", border: "1px solid white", borderRadius: "50%", padding: "4px", cursor: "pointer" }} /></div>



        </div>

        <div className="swiper-pagination"></div>

        <div className="" style={{ border: "" }}>

          <div className="swiper-button-next slider-arrow" style={{ border: "", }}></div>

          <div><FaLongArrowAltRight style={{ width: "25px", height: "25px", color: "white", border: "1px solid white", borderRadius: "50%", padding: "4px", cursor: "pointer" }} /></div>


        </div>

      </div>


    </div>

  </div>

  

</div>
    
    </>
  )
}

export default MasterOffers;
export {SliderBk}