import React, { useState } from 'react'
import Banner from '../Components/Banner'
import './/../Master css/MasterFaq.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const MasterFaq = () => {

  const [dropDown, setDropDown] = useState(false)
  const [dropDown1, setDropDown1] = useState(false)
  const [dropDown2, setDropDown2] = useState(false)
  const [dropDown3, setDropDown3] = useState(false)
  const [dropDown4, setDropDown4] = useState(false)
  const [dropDown5, setDropDown5] = useState(false)


  return (
    <>

    

    <Banner/>
    <section className="sec-area">
      <div className="container1">
        <div className="row ">
          <h2 className="wow  animated animated text-header animated" data-wow-delay=".5s" style={{visibility:"visible",animationDelay:"0.5s"}} > FAQ </h2>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
          <div className="accordion">
          <div className={`accordion-item ${dropDown===!false?"bg-indigo-50":""}`}>
              <div className="accordion-header" onClick={()=>setDropDown(!dropDown)}>
                <h5>General</h5>
                <div className="arrow ">
                {
                !dropDown?  <i className={`fas fa-regular fa-plus ` }></i>:null
                 }
                  {
                  dropDown? <i class="fas fa-regular fa-minus"></i>:null
                  }
                
                </div>
              </div>
              {
               dropDown?<div className="accordion-content">
              <h5 className="subhead-pading">1. What are the advantages of purchasing a bus ticket with BookUrSelf?</h5>
                <p className="linemargin" data-wow-delay=".5s">Answer : BookUrSelf is the fastest growing bus ticket company in India and you can find the largest bus seats on their site. Some of the advantages of working with us are</p>
                <p className="linemargin" data-wow-delay=".5s">- You can choose your seat.</p>
                <p className="linemargin" data-wow-delay=".5s">- You can book your bus tickets online.</p>
                <p className="linemargin" data-wow-delay=".5s">- You can choose from many operators.</p>
                <p className="linemargin" data-wow-delay=".5s">- You can choose from buses based on boarding points, timing and bus type. </p>
                <h5 className="subhead-pading">2. Do I need to register to use BookUrself?</h5>
                <p className="linemargin" data-wow-delay=".5s">Answer : Yes bookUrself require its customers to go through a registration process</p>
                <h5 className="subhead-pading">3. Does booking online cost me more?</h5>
                <p className="linemargin" data-wow-delay=".5s">Answer : Not at all! Bus ticket prices are the same as you get from the bus company. Depending on the season and period, the price may be the same or discounted.</p>
                </div>:null
                }
              </div>
              <div className={`accordion-item ${dropDown1===!false?"bg-indigo-50":""}`}>
              <div className="accordion-header" onClick={()=>setDropDown1(!dropDown1)}>
                <h5>Ticket Related</h5>
                <div className="arrow">
                {
                !dropDown1?  <i className={`fas fa-regular fa-plus ` }></i>:null
                 }
                  {
                  dropDown1? <i class="fas fa-regular fa-minus"></i>:null
                  }
                
                </div>
              </div>
              {
               dropDown1? <div className="accordion-content">
              <h5 className="subhead-pading">4. Is it mandatory to take a printout of the ticket?</h5>
                <p className="linemargin" data-wow-delay=".5s">Answer : It depends on the bus company where you booked the ticket. Travel is authorized by presenting the SMS sent to your mobile phone upon boarding.</p>
              <h5 className="subhead-pading">5. I've lost my ticket. What should I do now?</h5>
                <p className="linemargin" data-wow-delay=".5s">Answer : A copy of the ticket would have been sent to you by email when you booked it. Please take a printout of that mail and produce it at the time of boarding. If you have not received the ticket e-mail, please call any of our call centers and our executive will resend you a copy by mail.</p>
              </div>:null
              }
            </div>
            <div className={`accordion-item ${dropDown2===!false?"bg-indigo-50":""}`}>
              <div className="accordion-header" onClick={()=>setDropDown2(!dropDown2)}>
                <h5>Payment related</h5>
                <div className="arrow">
                {
                !dropDown2?  <i className={`fas fa-regular fa-plus ` }></i>:null
                 }
                  {
                  dropDown2? <i class="fas fa-regular fa-minus"></i>:null
                  }
                
                </div>
              </div>
              {
              dropDown2?<div className="accordion-content">
              <h5 className="subhead-pading">6. Does the owner of the credit card with which the ticket is purchased need to be one of the passengers?</h5>
                <p className="linemargin" data-wow-delay=".5s">Answer : Not at all! Passengers can use any card to pay for tickets, not necessarily theirs. However, please note that the passenger whose name you have booked the ticket with must carry their ID (along with the ticket) when boarding the bus. </p>
                <h5 className="subhead-pading">7. What payment options do I have?</h5>
                <p className="linemargin" data-wow-delay=".5s">Answer : The different payment options are</p>
                <p className="linemargin" data-wow-delay=".5s">a.UPI Apps</p>
                <p className="linemargin" data-wow-delay=".5s">b.Credit card</p>
                <p className="linemargin" data-wow-delay=".5s">c.Internet banking (Internet enabled online bank account)</p>
                <p className="linemargin" data-wow-delay=".5s">d.Wallets</p>
               
                
                <h5 className="subhead-pading">8. How does the transaction appear on my card / account statement?</h5>
                <p className="linemargin" data-wow-delay=".5s">Answer : Transactions on BookUrSelf will appear as BOOKURSELF.com, wwwbookurself.com in your bank statement.</p>
                <h5 className="subhead-pading">9. Is it safe to use my credit card on this site?</h5>
                <p className="linemargin" data-wow-delay=".5s">Answer : Transactions on BookUrSelf are very secure. We use top-notch security and any transactions made are safe. BookUrSelf uses Secure Socket Layers (SSL) data encryption. By using SSL, information exchanged with us is not transmitted unencrypted and prevents unauthorized persons from viewing the information.</p>

              </div>:null
              }
            </div>
            <div className={`accordion-item ${dropDown3===!false?"bg-indigo-50":""}`}>
              <div className="accordion-header"  onClick={()=>setDropDown3(!dropDown3)}>
                <h5>Cancellation related</h5>
                
                <div className="arrow">
                  {
                !dropDown3?  <i className={`fas fa-regular fa-plus ` }></i>:null
                 }
                  {
                  dropDown3? <i class="fas fa-regular fa-minus"></i>:null
                  }
                
                </div>

              </div>
              {
              dropDown3? <div className="accordion-content">
                
                <h5 className="subhead-pading">10. Can I cancel my ticket online?</h5>
                <p className="linemargin" data-wow-delay=".5s">Answer : Most of the tickets can be canceled online. However, there are some tickets that can only be canceled through our call center.</p>
                <p className="linemargin" data-wow-delay=".5s">However please note that the cancellation fee and cancellation period may differ for specific bus services. Please contact any of our executives for cancellation details on any specific service.</p>
                <h5 className="subhead-pading">11. How can I cancel my bus ticket online?</h5>
                <p className="linemargin" data-wow-delay=".5s">Answer : You need to click on the cancellation link provided on our home page. Enter your ticket number and the e-mail ID that was provided at the time of booking and click on cancel ticket.</p>
             
              </div>:null
              }
            </div>
            <div className={`accordion-item ${dropDown4===!false?"bg-indigo-50":""}`}>
              <div className="accordion-header" onClick={()=>setDropDown4(!dropDown4)}>
                <h5>Refund related</h5>
                <div className="arrow">
                {
                !dropDown4?  <i className={`fas fa-regular fa-plus ` }></i>:null
                 }
                  {
                  dropDown4? <i class="fas fa-regular fa-minus"></i>:null
                  }
                
                 
                </div>
              </div>
              {dropDown4?
              <div className="accordion-content">
              <h5 className="subhead-pading">12. I missed the bus. Do I get a refund?</h5>
                <p className="linemargin" data-wow-delay=".5s">Answer : BookUrSelf provides a 100% refund if the bus is missed due to either BookUrSelf or its’ partner company's fault. However, if the bus is missed due to any other reason not directly related to BookUrSelf no refund is provided.</p>
              <h5 className="subhead-pading">13. How can I get a refund in case I cancel my ticket?</h5>
                <p className="linemargin" data-wow-delay=".5s">The refund is provided as per with our cancellation policy. The refund can be credited to the source of payment (Example: debit card, credit card, net banking) or credited to BookUrSelf wallet. Wallet credit can be used for bus booking in future (within 6 months of cancellation).</p>
                <h5 className="subhead-pading">14. How much refund do I get if I cancel tickets?</h5>
                <p className="linemargin" data-wow-delay=".5s">Answer : The amount refunded for the cancellation of a travel ticket will depend on the travel service provider agencies.</p>
                <h5 className="subhead-pading">15. Insurance related</h5>
                <p className="linemargin" data-wow-delay=".5s">For details on Insurance please refer to Insurance Terms</p>
                <p className="linemargin" data-wow-delay=".5s">Promocode can be reusable for a single user or one time</p>
                <p className="linemargin" data-wow-delay=".5s">Avail normal or not…</p>
              </div>:null }
            </div>
            <div className={`accordion-item ${dropDown5===!false?"bg-indigo-50":""}`}>
              <div className="accordion-header" onClick={()=>setDropDown5(!dropDown5)}>
                <h5>Referral program / Membership related</h5>
                <div className="arrow">
                {
                !dropDown5?  <i className={`fas fa-regular fa-plus ` }></i>:null
                 }
                  {
                  dropDown5? <i class="fas fa-regular fa-minus"></i>:null
                  }
                
                </div>
              </div>
              {dropDown5?
              <div className="accordion-content">
                <h5 className="subhead-pading">16. How to participate in a referral program?</h5>
                <p className="linemargin" data-wow-delay=".5s">Answer : Only the members can participate in the referral program, by paying minimum membership fees the user can become a member and participate in the referral program.</p>
                <h5 className="subhead-pading">17. How does this referral program work?</h5>
                <p className="linemargin" data-wow-delay=".5s">Answer : All registered members will be having unique url and their referral code, new users who use your code and register themself for membership particular chashpoints will be added in your wallet.</p>
                <h5 className="subhead-pading">18. What is the wallet amount?</h5>
                <p className="linemargin" data-wow-delay=".5s">Answer : Canceled tickets refunded or cash points earned through referral program will be added in the wallet which can be used to book your travel ticket.</p>
                <h5 className="subhead-pading">19. What are the advantages of membership to BookUrSelf?</h5>
                <p className="linemargin" data-wow-delay=".5s">Answer : Members have following advantages</p>
              
                <p className="linemargin" data-wow-delay=".5s">1. They can participate in a referral and earn program to earn cash points (earned cas points will be added in wallet).</p>
                <p className="linemargin" data-wow-delay=".5s">2. They will be getting extra discounts or benefits while booking travel tickets during offers (Promo codes) released on special occasions.</p>
              </div>:null
              }
            </div>
          </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default MasterFaq