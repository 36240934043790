
import './/../Master css/MasterContactUs.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEnvelope,faLocationDot,faPhone,} from '@fortawesome/free-solid-svg-icons'
import FooterImage from '../Components/FooterImage'
import Banner from '../Components/Banner'
import { Link } from 'react-router-dom'


const MasterContactUs = () => {
    


const ContactInfo = [

    {
        icon:<FontAwesomeIcon icon={faLocationDot} />,
        name:"Address",
        info:"HOPCOMS, Society CTS No. 3921, A1/A, 2nd Floor, Court Campus, Belagavi, Karnataka 590001"

    },

    {
        icon:<FontAwesomeIcon icon={faPhone} />,
        name:"Phone",
        info:"Mobile: +91 9964 566 363"

    },

    {
        icon:<FontAwesomeIcon icon={faEnvelope} />,
        name:"Email",
        info:"customercare@bookurself.com"

    },
]


const  SocialIcons = [

    {
        logo:<i className="fa-brands fa-facebook-f"></i>,
    },

    {
        logo:<i className="fa-brands fa-twitter"></i>,
    },

    {
        logo:<i className="fa-brands fa-instagram"></i>,
    },
]



  return (
    <>


    <Banner/>


    <section className="contact-area section--padding">
        <div className="container1">
            <div className="row">
                <div className="col-lg-8" >
                    <div className="form-box">
                        <div className="form-title-wrap">
                            <h3 className="title text-[18px]">We'd love to hear from you</h3>
                            <p className="text-[15px]">Send us a message and we'll respond as soon as possible</p>
                        </div>
                        <div className="form-content p-[20px]">
                            <div className="contact-form-action">
                                <form method="post" className="row">
                                    <div className="col-lg-6 responsive-column">
                                        <div className="input-box">
                                            <label className="label-text" >Your Name</label>
                                            <div className="form-group">
                                                <i className="fa-regular fa-user form-icon"></i>
                                                <input className="form-control" type="text" name="text" placeholder="Your name"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 responsive-column">
                                        <div className="input-box">
                                            <label className="label-text">Your Email</label>
                                            <div className="form-group">
                                                <i className="fa-regular fa-envelope form-icon"></i>
                                                <input className="form-control" type="email" name="email" placeholder="Email address"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="input-box">
                                            <label className="label-text">Message</label>
                                            <div className="form-group">
                                                <i className="fa-regular fa-pen-to-square form-icon"></i>
                                                <textarea className="message-control form-control" name="message" placeholder="Write message"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="col-lg-12">
                                        <div className="btn-box">
                                            <button type="button" className="theme-btn">Send Message</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="form-box">
                        <div className="form-title-wrap">
                            <h3 className="title">Contact Us</h3>
                        </div>
                        <div className="form-content">
                            <div className="address-book">
                                <ul className="list-items contact-address">
                                    {ContactInfo.map((item,index)=>(
                                    <li >
                                        <i className=" icon-element " style={{textAlign:"center"}}>{item.icon}</i>
                                        <label className="label-text " style={{fontSize:"16px"}}>{item.name}</label>
                                        <p className="map__desc" style={{fontSize:"16px"}}>{item.info}</p>
                                    </li>
                                    ))}
                                </ul>

                                <ul className="social-profile text-center ban-bottom ">
                                    {SocialIcons.map((val,index)=>(
                                    <li><Link>{val.logo}</Link></li>
                                    ))}                    
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <FooterImage/>

    </>
  )
}

export default MasterContactUs