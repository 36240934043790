import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MasterHome from "./Master Pages/MasterHome";
import MasterAboutUs from "./Master Pages/MasterAboutUs";
import Footer from "./Components/Footer";
import MasterContactUs from "./Master Pages/MasterContactUs";
import MasterTerm from "./Master Pages/MasterT&C";
import MasterFaq from "./Master Pages/MasterFaq";
import Header from "./Components/Header";
import StickyNavbar from "./Components/StickyNavbar";
import MasterOffers from "./Master Pages/MasterOffers";
import OnBusOffer from "./Components/OffersDetails/OnBusOffer";
import HolyBusOffer from "./Components/OffersDetails/HolyBusOffer";
import FirstBusOffer from "./Components/OffersDetails/FirstBusOffer";
import GoTopBtn from "./Components/OffersDetails/GoTopBtn";
import ProfilePage from "./Components/LoginDetailPages/ProfilePage";
import EliteMemberPage from "./Components/LoginDetailPages/EliteMemberPage";
import MyBookings from "./Components/LoginDetailPages/MyBookings";
import BookingConfirmation from "./Components/LoginDetailPages/BookingConfirmation";
import PassengerInfo from "./Components/LoginDetailPages/PassengerInfo";
import MasterPrivacyPolicy from "./Master Pages/MasterPrivacyPolicy";
import ForgetPassword from "./Components/LoginDetailPages/ForgetPassword";
import VerificationCode from "./Components/LoginDetailPages/VerificationCode";
import NewPassword from "./Components/LoginDetailPages/NewPassword";

const Routers = () => {
  return (
    <>
      <BrowserRouter>

        <Header />
  
        <Routes>
          <Route path="/" element={<MasterHome />} />
          <Route path="/about" element={<MasterAboutUs />} />
          <Route path="/contact" element={<MasterContactUs />} />
          <Route path="/offers" element={<MasterOffers />} />
          <Route path="/terms" element={<MasterTerm />} />
          <Route path="/faq" element={<MasterFaq />} />
          <Route path="/onbusoffer" element={<OnBusOffer />} />
          <Route path="/holybusoffer" element={<HolyBusOffer />} />
          <Route path="/firstbusoffer" element={<FirstBusOffer />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/elitemember" element={<EliteMemberPage />} />
          <Route path="/mybookings" element={<MyBookings />} />
          <Route path="/selectseat" element={<BookingConfirmation />} />
          <Route path="/passangerinfo" element={<PassengerInfo />} />
          <Route path="/privacy" element={<MasterPrivacyPolicy />} />
          <Route path="/forgotpassword" element={<ForgetPassword/>} />
          <Route path="/verifycode" element={<VerificationCode/>} />
          <Route path="/newpassword" element={<NewPassword/>} />





        </Routes>
        
        <GoTopBtn/>
        <Footer />
        <StickyNavbar />
      </BrowserRouter>
    </>
  );
};

export default Routers;
