import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ".//../Master css/MasterHome.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";
import { IoBusOutline } from "react-icons/io5";
import { BsCalendar2Day } from "react-icons/bs";
import { GoArrowSwitch } from "react-icons/go";
import { IoMdCheckmark } from "react-icons/io";
import { IoIosStar } from "react-icons/io";
import { IoIosStarHalf } from "react-icons/io";
import { FaLongArrowAltRight } from "react-icons/fa";
import { motion } from 'framer-motion'

import Swiper from 'swiper';
import MasterOffers, { SliderBk } from "./MasterOffers";



const MasterHome = () => {
  const navigate = useNavigate();

  const goto = () => {
    navigate("/about");
  };

  const BenifitSection = [
    {
      icon: "fa-solid fa-shield-halved",
      heading: "100%",
      desc: "SAFETY",
    },

    {
      icon: "fa-solid  fa-indian-rupee-sign  sky-wd-104",
      heading: "Travel at",
      desc: "LOWEST PRICE",
    },

    {
      icon: "fa-solid fa-route fa-4x ",
      heading: "1,00,000 +",
      desc: "ROUTES AVAILABLE",
    },

    {
      icon: "fa-solid fa-bus fa-4x sky-why-us",
      heading: "2,500 +",
      desc: "BUS PARTNERS",
    },
  ];

  const OfferSection = [
    {
      image: "/img/offer/image-1.png",
      name: "ONBUS",
      link: "/onbusoffer"

    },

    {
      image: "/img/offer/image-2.png",
      name: "HOLYBUS",
      link: "/holybusoffer"

    },

    {
      image: "/img/offer/image-3.png",
      name: "FIRSTBUS",
      link: "/firstbusoffer"

    },


  ];

  

  return (
    <>
      {/* //////////////////////  Booking Section //////////////////////////////////////////////// */}



      <div className="banner" style={{background:"url('/images/pencilsketch.png')",position:"relative",objectFit:"cover",WebkitBackgroundSize:"cover",minHeight:"400px",backgroundPosition:"center",backgroundSize:"cover", backgroundRepeat:"no-repeat",display:"flex",flexDirection:"column", justifyContent:"space-between", }}>
        

      {/* <div class="container1">
            <div class="bann-info">
                <h1 class="wow fadeInDown animated animated animated" data-wow-delay=".5s" style={{visibility:"visible", animationDelay:"0.5s"}}> 
                </h1>
            </div>
            
        </div> */}
            
      
        <div className="container1" style={{position:"relative",height:"",}}>
      
            <div className="row" style={{background:"none",borderRadius:"10px",}} >
                <div className="wow fadeInDown  animated  mar-20px" data-wow-delay=".5s" style={{padding:"5px 10px",position:"relative"}}>
                    <div className="bnr-left">
                        <div className="col-sm-3 sky-home rad-left " style={{marginBottom:"5px"}}>

                          <div className="bnr_div">

                           <div className="bnr_div1">

                          <IoBusOutline style={{ height: "30px", width: "30px" }} />

                           </div>

                          <div className="bnr_div2">

                          <input type="text" placeholder="From" />
                 
                          </div>

                          </div>


                        </div>



                                 <div className="col-sm-1 arrow_section">

                                     <GoArrowSwitch />

                                  </div>


                        <div className="col-sm-3 sky-home sky-border-none" style={{marginBottom:"5px"}}>
                        
                        <div className="bnr_div">

                           <div className="bnr_div1">

                          <IoBusOutline style={{ height: "30px", width: "30px" }} />

                           </div>

                          <div className="bnr_div2">

                          <input type="text" placeholder="To" />
                 
                          </div>

                          </div>

                        </div>




                        <div className="col-sm-3 sky-home sky-border-none" style={{marginBottom:"5px"}}>

                          <div className="bnr_div">

                          <div className="bnr_div1">

                        <BsCalendar2Day style={{ height: "30px", width: "30px" }} />
                     
                         </div>

                        <div className="bnr_div2" style={{textAlign:"start"}}>

                           <div>Date</div>

                       </div>

                          </div>
                            
                        </div>

                                            

                        <div className="col-sm-2 sky-home rad-rgt sky-border-none" style={{marginBottom:"5px",display:"flex", justifyContent:"center", backgroundColor:"purple", color:"white"}} >
                            <button type="button" className="seabtn" id="" >
                                Search
							               </button>
                        </div>

                    </div>
                
                
                  
                    

                </div>
            </div>
        </div>

        <div  className="" style={{position:"relative", height:"20%",width:"100%", bottom:"0px",marginTop:""}} >

          <div className="bus_shape">
          
        <img src="/images/bus.png" alt=""  className=""/>

        </div>

        </div>
        
    </div>


      {/* //////////////////////////////////////////////////////////////////////////////// */}







      {/* ////////////////////////////////////// image section /////////////////////////////////////// */}

      <div className="mid-img" style={{ background: "url('/images/new.jpg')" }}>
        <img src="" alt="" />
      </div>

      {/* //////////////////////////////////////////////////////////////////////////////////////////////////// */}

      {/* /////////////////////////// scrolling bus and city ///////////////////////// */}

      <div className="city_wrapper  mb-[50px] bg-indigo-900">

        <div className="item item1">MUMBAI</div>
        <div className="item item2">BANGALORE</div>
        <div className="item item3">HYDERABAD</div>
        <div className="item item4">CHENNAI</div>
        <div className="item item5">PUNE</div>
        <div className="item item6">MYSORE</div>
        <div className="item item7">SURAT</div>
        <div className="item item8">KOLKATTA</div>
        <div className="item item9">KOLHAPUR</div>
        <div className="item item10">TIRUPATHI</div>

      </div>


      {/* ////////////////////////////////////////////////////////////////////////////// */}

      {/* /////////////////////////////////// Offer Section  //////////////////////////////////////////// */}



        <SliderBk  />

 

      {/* ///////////////////////////////////// Why Book With Us  section  ////////////////////////////// */}

      <div className="routes my-5">
        <div className="container1">
          <div className="rupes">
            <b>
              <h1
                align="center"
                className="rupes-left wow fadeInDown animated animated animated"
                style={{
                  fontFamily: "Lato, sans-serif",
                  color: "rgb(192, 24, 150)",
                  fontSize: "30px",
                  fontWeight: "bold",
                  visibility: "visible",
                  animationName: "fadeInDown",
                }}
              >
                Why Book With Us
              </h1>
            </b>
            <div
              className="row mx-auto my-auto "
              id="promo_codes"
              align="center"
            ></div>

            <div className="container1 my-5">
              <div className="row ">
                {BenifitSection.map((item, index) => (
                  <div 
                    className={`col-sm-3 mt-5 mt-sm-0 `}
                    style={{ padding: " 0 10px" }}
                  >
                    <div className="card card1 ">
                      <div
                        className=" wow fadeInDown  animated my-5 animated"
                        data-wow-delay=".5s"
                        style={{
                          visibility: "visible",
                          animationDelay: "0.5s",
                          animationName: "fadeInDown",
                        }}
                      >
                        <div>
                          <i className={`${item.icon} fa-4x sky-why-us`}></i>

                          <br />
                          <br />
                          <h3>{item.heading}</h3>
                          <p>{item.desc}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="container1 my-5"></div>
            </div>
          </div>
        </div>
      </div>

      {/* /////////////////////////////////////////////////////////////////////////////// */}

      {/* //////////////////////// DownLoad Mobile App Section ////////////////////////////////////// */}


    
    <div className="container1  playstore_main" style={{borderRadius:"15px", padding:"30px",position:"relative",border:"1px solid rgba(0,0,0,0.1)"}}>

        <div className=" border-[1px] rounded-2xl ply_store" style={{padding:"",display:"flex",justifyContent:"space-around"}}>


          <div className="ply_div1" >
            {/* <h3 className="text-red-500" style={{marginBottom:"20px"}}>Quick acces</h3> */}
            <img src="/images/mobile.png" alt="" style={{width:"220px", height:"260px",position:"relative",rotate:"30deg"}} />
          </div>

          <div className="ply_div2 flex justify-center items-center flex-col"  >

            <div className="rounded-2xl p-6" style={{height:"60%", backgroundColor:"white"}}>

              <div className="ply_dnw">

              <ul>

              <li><span><FaLongArrowAltRight  className="icon"/></span>Quick Access</li>
              <li><span><FaLongArrowAltRight  className="icon"/></span>Live Tracking</li>

             </ul>

              </div>

           

            <h3 className="text-[22px] text-center font-bold mt-4 mb ply_div2_heading  ">Rating</h3>

            <div className="ply_icons mt-3 flex  justify-center" style={{}}>
            <span><IoIosStar /></span>
            <span><IoIosStar /></span>
            <span><IoIosStar /></span>
            <span><IoIosStar /></span>
            <span><IoIosStarHalf /></span>
            </div>

            </div>

          </div>

          <div className="ply_div3" style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
            
          <h3 className=" text-[28px] text-center playstore_heading">Make Your Journey Special <br/> With Bookurself App</h3>
{/* 
          <img src="/images/1.png" alt="" style={{width:"120px"}} /> */}


          <div className="playstore_spl mt-5">
            <ul >
              <li><span><IoMdCheckmark  className="icon"/></span>Get Notified</li>
              <li ><span><IoMdCheckmark  className="icon" /></span>Trusted By Millions</li>
              <li><span><IoMdCheckmark   className="icon" /></span>Enjoy Other Offers</li>
            </ul> 
          </div>

          <div className="playstore_image mb-4" style={{ width:"", display:"flex", justifyContent:"space-evenly", marginTop:"10px"}}>

          <img src="/images/1.png" alt="" style={{width:"120px", }}/>

          <img src="/images/2.png" alt="" style={{width:"120px", }}/>

          </div>

              

          </div>

         

        </div>

        </div>


   

      <div className="container1 mt-[17px]" style={{border:""}} >
        <div
          className="track row"
          style={{
            background: "url('/images/app_background.png')",
            backgroundRepeat: "no-repeat",
            marginBottom: "3em ",
          }}
        >
          <div
            className="col-md-9 track-left wow fadeInDown animated animated"
            data-wow-delay=".5s"
            style={{
              visibility: "visible",
              animationDelay: "0.5s",
              animationName: "fadeInDown",
            }}
          >
            <div className="app">
              {/* <h3>DOWNLOAD MOBILE APP</h3> */}
              <div className="app-button" style={{}}>
                {/* <a
                  href="https://play.google.com/store/apps/details?id=com.infysky.bookurself&amp;hl=en"
                  target="_blank"
                >
                  <img
                    className="play-store"
                    src="/images/1.png"
                    alt="playstore"
                  />
                </a> */}
                {/* <a href="https://play.google.com/store/apps/details?id=com.infysky.bookurself&amp;hl=en">
                  <img
                    src="/images/mobile.png"
                    width="220px"
                    height="200px"
                    className="img-responsive"
                    alt=""
                  />
                </a> */}
              </div>
            </div>
          </div>

     
        </div>
      </div>
    </>
  );
};

export default MasterHome;
