import React, { useState } from "react";
import "..//../Components css/Logindetails.css";
import { useNavigate } from "react-router";


const BookingConfirmation = () => {

  const navigate = useNavigate();

  const [openState, setOpenState] = useState(0)

  const [selectSeat, setSelectSeat] = useState(1)

  const checkStatus = (index)=>{
    setOpenState(index)
  }

  const confirmSeat =(index)=>{

    setSelectSeat(index)
  }
  
  const  navToPassInfo =()=>{
    navigate("/passangerinfo")
  }

  return (
    <>
      <section className=" margin-top-20px margin-bottom-55px">
        <div className="container1">
          <div className="offer-container">
            <div className="row">
              <div className="sky-sub-title">
                <span id="lblSrc">Hyderabad</span>
                <i className="fa-solid fa-arrow-right-long sky-color"></i>
                <span id="lblDest">Bangalore</span>
              </div>

              <div className="container-route ">
                <div className="card-route" id="">
                  <div className="row search-header ">
                    <div className="width-80">
                      <div>
                        <i
                          className="fa fa-bus sky-grey"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div className="book-seat-layout text-white">
                        IntrCity SmartBus
                      </div>
                    </div>
                    <div className="width-20">
                      <span className="book-upcoming sky-600 pull-right sky-grey">
                        32 Seats Available
                      </span>
                    </div>
                  </div>
                  <div className="sky-line mar-0"></div>

                  <div className="bus-info">
                    <div className="bus-timing">
                      <div className="bus-time bus-start-time">
                        <label className="bold-label">01:20 PM</label>

                       
                      </div>
                      <div className="bus-time bus-route-arrow">
                        <p>
                          <label className="light-label">18h 10m</label>
                        </p>
                        <img
                          className="img-route"
                          src="/images/path-icon.png"
                          alt=""
                        />
                      </div>
                      <div className="bus-time bus-end-time">
                        <label className="bold-label">07:30 AM</label>

                      </div>
                    </div>
                    <div className="bus-type">
                      <p className="bold-label">
                        2+1 AC Sleeper Bharat Benz Full Air Suspention Washroom
                      </p>
                    </div>

                    <div className="bus-fair">
                      <p>Starts At</p>
                      <span>
                        <label className="bold-label">
                          {" "}
                          <i className="fa-solid fa-indian-rupee-sign"></i>
                          5000.0
                        </label>
                      </span>
                    </div>
                  </div>

                  <div className="sky-line mar-0"></div>

                  <div className="bus-route-details">
                    <div className="bus-amenities">
                      <ul className="nav nav-tabs">
                        <li className={``} onClick={()=> checkStatus(1)}>

                          <p className={`${openState === 1 ? "act-aa":"nav-tabs-aa"}`}>
                          
                            Boarding / Dropping Point

                            </p>
                          
                        </li>

                        <li className={``} onClick={()=> checkStatus(2)}>
                          
                           <p className={`${openState === 2 ? "act-aa":"nav-tabs-aa"}`}>
                            Cancellation Policy
                            </p>
                          
                        </li>

                     

                        <li className="pull-right " onClick={()=> setOpenState(3)}>
                        <p className={`${openState === 3 ? "act-aa":"nav-tabs-ab"} ` } style={{padding:"8px 15px"}}>
                            Select Seat
                            </p>
                        
                        </li>
                      </ul>
                    </div>


                    {/* ///////////////////////////////////////////////////////////////////////////////////////////// */}

                    <div className=" tab-conte">
                      {/* ///////////////////////   first one   ////////////////////////////// */}

                      <div id="" className="tab-pane">
                                            <div className={`boarding-dropping-tab-section  ${openState === 1 ? "block":"hidden"}`}>
                                                <div className={`boarding-point-tab  ` } >
                                                    <div className="boarding-point-list"  style={{margin:""}}>
                                                        <div className="boarding-title">Boarding Point</div>
                                                        <div className="boarding-list-type">
                                                            <div className="boarding-list">
                                                                <label>01:20:00 PM</label>

                                                               

                                                                <p>Nampally (Van Pickup),Shar Travels, Near- Railway Station (Hyderabad),7303093510 </p>
                                                            </div>

                                                            <div className="boarding-list">
                                                                <label>01:00:00 PM</label>

                                                               

                                                                <p>BHEL (Van Pickup),Towards Lingampally Road (Hyderabad),7303093510 </p>
                                                            </div>

                                                            <div className="boarding-list">
                                                                <label>06:00:00 PM</label>


                                                                <p>Madinaguda (Van Pickup),Opp. Bajaj Electronics (Hyderabad),7303093510 </p>
                                                            </div>

                                                            <div className="boarding-list">
                                                                <label>06:15:00 PM</label>


                                                                <p>Miyapur Alwin X Roads (Van Pickup),Opp. Agarwal Eye Hospital (Hyderabad),7303093510 </p>
                                                            </div>

                                                            <div className="boarding-list">
                                                                <label>06:30:00 PM</label>

                                                            

                                                                <p>Miyapur (Van Pickup),Opp. Bommarillu Restaurant, Near- Bus Stand (Hyderabad),7303093510 </p>
                                                            </div>

                                                            <div className="boarding-list">
                                                                <label>06:45:00 PM</label>

                                                                

                                                                <p>JNTU (Van Pickup),Near- Ganesh Marbles (Hyderabad),7303093510 </p>
                                                            </div>

                                                            <div className="boarding-list">
                                                                <label>07:00:00 PM</label>

                                                               
                                                                <p>KPHB (Van Pickup),Near- JC brothers (Hyderabad),7303093510 </p>
                                                            </div>

                                                            <div className="boarding-list">
                                                                <label>12:05:00 AM</label>

                                                               

                                                                <p>Kukatpally Test,Near- BJP Office (Hyderabad),7303093510 </p>
                                                            </div>

                                                            <div className="boarding-list">
                                                                <label>08:30:00 PM</label>

                                                              

                                                                <p>Moosapet Test,Mumbai Hwy, Habeeb Nagar, Moosapet, Hyderabad (Hyderabad),7303093510 </p>
                                                            </div>

                                                            <div className="boarding-list">
                                                                <label>08:35:00 PM</label>

                                                               
                                                                <p>Bharath Nagar,Near- Pride of Hyderabad Hotel (Hyderabad),7303093510 </p>
                                                            </div>

                                                            <div className="boarding-list">
                                                                <label>08:40:00 PM</label>

                                                             
                                                                <p>Erragadda,Near- Metro station (Hyderabad),7303093510 </p>
                                                            </div>

                                                            <div className="boarding-list">
                                                                <label>08:45:00 PM</label>

                                                              
                                                                <p>ESI Test,Near- Metro station (Hyderabad),7303093510 </p>
                                                            </div>

                                                            <div className="boarding-list">
                                                                <label>08:55:00 PM</label>

                                                              

                                                                <p>S R Nagar,Near- Metro Station (Hyderabad),7303093510 </p>
                                                            </div>

                                                            <div className="boarding-list">
                                                                <label>09:05:00 PM</label>


                                                                <p>Ameerpet Test,Near- Metro Station (Hyderabad),7303093510 </p>
                                                            </div>

                                                            <div className="boarding-list">
                                                                <label>09:10:00 PM</label>

                                                              

                                                                <p> IntrCity SmartBus Lounge Gachibowli,2-76/5,HMI Towers, below Linen Club, Chhota Anjaiah Nagar, Gachibowli (Hyderabad),7303093510 </p>
                                                            </div>

                                                            <div className="boarding-list">
                                                                <label>09:15:00 PM</label>
                                                                
                                                                <p>Panjagutta,Near- Metro station (Hyderabad),7303093510 </p>
                                                            </div>

                                                            <div className="boarding-list">
                                                                <label>09:20:00 PM</label>
                                                            
                                                                <p>Lakdikapool,Infront Of Sandhya Hotel, Pillar No. 1200 (Hyderabad),7303093510 </p>
                                                            </div>

                                                            <div className="boarding-list">
                                                                <label>09:25:00 PM</label>
                                                             
                                                                <p>Tolichowki,Tolichowki (Van Pickup) (Hyderabad),7303093510 </p>
                                                            </div>

                                                            <div className="boarding-list">
                                                                <label>10:00:00 PM</label>
                                                            
                                                                <p>Shamshabad,Infront of Taj Restaurant Test (Hyderabad),7303093510 </p>
                                                            </div>

                                                            <div className="boarding-list">
                                                                <label>12:25:00 AM</label>
                                                               
                                                                <p>Kachiguda,Opp. Railway Station (Hyderabad),7303093510 </p>
                                                            </div>

                                                            <div className="boarding-list">
                                                                <label>12:50:00 AM</label>
                                                            
                                                                <p>Jadcherla,Flyover Ending (Hyderabad),7303093510 </p>
                                                            </div>

                                                            <div className="boarding-list">
                                                                <label>01:00:00 AM</label>
                                                             
                                                                <p>Balanagar,Near- RK Travels (Hyderabad),7303093510 </p>
                                                            </div>



                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="dropping-point-tab  " >
                                                    <div className="dropping-point-list"  >
                                                        <div className="boarding-title">Dropping Point</div>
                                                        <div className="boarding-list-type dropping-list-type">
                                                            <div className="boarding-list dropping-list">
                                                                <div><label>05:15:00 AM</label><label className="dropdate">19-Apr</label>
                                                                </div>
                                                                <p>Chikkaballapur,Bypass (Bangalore),Bypass (Bangalore),7303093510</p>
                                                            </div>
                                                            <div className="boarding-list dropping-list">
                                                                <div><label>05:45:00 AM</label><label className="dropdate">19-Apr</label>
                                                                </div>
                                                                <p>Bial,Bial (Bangalore),Bial (Bangalore),7303093510</p>
                                                            </div>
                                                            <div className="boarding-list dropping-list">
                                                                <div><label>06:00:00 AM</label><label className="dropdate">19-Apr</label>
                                                                </div>
                                                                <p>Yelahanka Test,Yelahanka (Bangalore),Yelahanka (Bangalore),7303093510</p>
                                                            </div>
                                                            <div className="boarding-list dropping-list">
                                                                <div><label>06:15:00 AM</label><label className="dropdate">19-Apr</label>
                                                                </div>
                                                                <p>Esteem mall,Esteem mall (Bangalore),Esteem mall (Bangalore),7303093510</p>
                                                            </div>
                                                            <div className="boarding-list dropping-list">
                                                                <div><label>06:20:00 AM</label><label className="dropdate">19-Apr</label>
                                                                </div>
                                                                <p>Hebbal,Hebbal , Bangalore (Bangalore),Hebbal , Bangalore (Bangalore),7303093510</p>
                                                            </div>
                                                            <div className="boarding-list dropping-list">
                                                                <div><label>06:25:00 AM</label><label className="dropdate">19-Apr</label>
                                                                </div>
                                                                <p>Manyata Tech Park ,Bus Stop, Opp Of Indian Oil Petrol Bunk (Bangalore),Bus Stop, Opp Of Indian Oil Petrol Bunk (Bangalore),7303093510</p>
                                                            </div>
                                                            <div className="boarding-list dropping-list">
                                                                <div><label>06:30:00 AM</label><label className="dropdate">19-Apr</label>
                                                                </div>
                                                                <p>Kalyan Nagar,Kalyan Nagar (Bangalore),Kalyan Nagar (Bangalore),7303093510</p>
                                                            </div>
                                                            <div className="boarding-list dropping-list">
                                                                <div><label>06:35:00 AM</label><label className="dropdate">19-Apr</label>
                                                                </div>
                                                                <p>Babusapalya Test,Babusapalya (Bangalore),Babusapalya (Bangalore),7303093510</p>
                                                            </div>
                                                            <div className="boarding-list dropping-list">
                                                                <div><label>06:40:00 AM</label><label className="dropdate">19-Apr</label>
                                                                </div>
                                                                <p>Banaswadi Test,Banaswadi Bangalore (Bangalore),Banaswadi Bangalore (Bangalore),7303093510</p>
                                                            </div>
                                                            <div className="boarding-list dropping-list">
                                                                <div><label>06:45:00 AM</label><label className="dropdate">19-Apr</label>
                                                                </div>
                                                                <p>Ramamurthi Nagar,Ramamurthi Nagar Bangalore (Bangalore),Ramamurthi Nagar Bangalore (Bangalore),7303093510</p>
                                                            </div>
                                                            <div className="boarding-list dropping-list">
                                                                <div><label>06:50:00 AM</label><label className="dropdate">19-Apr</label>
                                                                </div>
                                                                <p>Tin Factory Test,Tin Factory Bangalore (Bangalore),Tin Factory Bangalore (Bangalore),7303093510</p>
                                                            </div>
                                                            <div className="boarding-list dropping-list">
                                                                <div><label>07:00:00 AM</label><label className="dropdate">19-Apr</label>
                                                                </div>
                                                                <p>K R Puram,Bangalore (Bangalore),Bangalore (Bangalore),7303093510</p>
                                                            </div>
                                                            <div className="boarding-list dropping-list">
                                                                <div><label>07:10:00 AM</label><label className="dropdate">19-Apr</label>
                                                                </div>
                                                                <p>Marathahalli Test,Marathahalli (Bangalore),Marathahalli (Bangalore),7303093510</p>
                                                            </div>
                                                            <div className="boarding-list dropping-list">
                                                                <div><label>07:15:00 AM</label><label className="dropdate">19-Apr</label>
                                                                </div>
                                                                <p>Kadubeesanahalli,Kadubeesanahalli (Bangalore),Kadubeesanahalli (Bangalore),7303093510</p>
                                                            </div>
                                                            <div className="boarding-list dropping-list">
                                                                <div><label>07:20:00 AM</label><label className="dropdate">19-Apr</label>
                                                                </div>
                                                                <p>Bellandur Test,Bellandur (Bangalore),Bellandur (Bangalore),7303093510</p>
                                                            </div>
                                                            <div className="boarding-list dropping-list">
                                                                <div><label>07:25:00 AM</label><label className="dropdate">19-Apr</label>
                                                                </div>
                                                                <p>HSR Layout Test,HSR Layout (Bangalore),HSR Layout (Bangalore),7303093510</p>
                                                            </div>
                                                            <div className="boarding-list dropping-list">
                                                                <div><label>07:30:00 AM</label><label className="dropdate">19-Apr</label>
                                                                </div>
                                                                <p>Silk Board Test,Bangalore (Bangalore),Bangalore (Bangalore),7303093510</p>
                                                            </div>
                                                            <div className="boarding-list dropping-list">
                                                                <div><label>10:30:00 AM</label><label className="dropdate">19-Apr</label>
                                                                </div>
                                                                <p>Jalahalli Cross,Jalahalli Cross (Bangalore),Jalahalli Cross (Bangalore),7303093510</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                      {/* ////////////////////////////////////////////////////////////////////  */}

                      {/* ///////////////////// second one /////////////////////////////// */}

                      <div id="cancel-tab-NXS_128_779_1003666_1001968_20240421" className={`tab-pane ${openState === 2 ? "block":"hidden"} `}>
                                            <div className="cancellation-tab-section">
                                                <div className="cancellation-policy">
                                                    <div className="cancellation-list-type">
                                                        <div className="cancellation-title">Cancellation Policy</div>
                                                        <div className="cancellation-list-title"><label>Cancellation Time
                                                                Before Departure</label><label>Amount Deduction(%)</label>
                                                        </div>
                                                        <div className="cancellation-list">
                                                            <p>Between 4 to24 Hrs</p>
                                                            <label>50%</label>
                                                        </div>
                                                        <div className="cancellation-list">
                                                            <p>Before 24 Hrs</p>
                                                            <label>25%</label>
                                                        </div>
                                                        <div className="cancellation-list">
                                                            <p>Stop Cancellation Within 240 Minutes</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                      {/* ///////////////////////////////////////////////////////////////////////                    */}

                      {/* ////////////////////////   THIRD ONE //////////////////// */}

                      <div
                        className={`tab-pane fade  in ${openState === 3 ? "block":"hidden" } `}
                        id=""
                      >
                        <div className="boarding-dropping-tab-section ">
                          <div className="boarding-point-tab">
                            <div className="boarding-point-list">
                              <div
                                className="content1 bus-lower-upper-tab"
                                id=""
                              >
                                <div className="bus-tabs width100">
                                  <p onClick={()=>confirmSeat(1)}
                                    className={`activate-bus-1 width50  ${selectSeat === 1 ?"active-bus-tab":""}`}
                                    >
                                    LOWER
                                  </p>
                                  <p onClick={()=>confirmSeat(2)} className={`activate-bus-2 width50 ${selectSeat === 2 ?"active-bus-tab":""}`} >
                                    UPPER
                                  </p>
                                  <div className="clear"></div>
                                </div>
                              </div>
{/* 
                              <div
                                className="columns large-7 "
                                style={{position:"relative"}}
                              > */}
                                <div
                                  id=""
                                  className={`top-layout tab-1  ${selectSeat === 1 ? "block" :"hidden"}`}
                                >
                                  <table
                                    id=""
                                    className="sky-tab"
                                    align="center"
                                    cellpadding=""
                                    cellspacing=""
                                    style={{width:"55%", textAlign:"center",}}
                                    
                                  >
                                    <tbody>
                                      <tr>
                                        <td className=" td_slepeer"></td>
                                        <td className=" td_slepeer"></td>
                                        <td className=" td_slepeer"></td>
                                        <td
                                          className="pad0 td_slepeer"
                                          
                                          top
                                        >
                                          <img
                                            src="/img/layout/steering-wheel-xxl.png"
                                            id=""
                                            alt=""
                                            style={{height:"35px", width:"35px", transform:"rotate(90deg)"}}
                                          />{" "}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          rowspan="2"
                                          id=""
                                          className=" td_slepeer"
                                        >
                                          <img
                                            src="/img/layout/hor_slepeer_avail.png"
                                            id=""
                                            className="hor-img-sleeper"
                                            alt=""
                                        
                                          />
                                        </td>
                                        <td className=" td_slepeer"></td>
                                        <td
                                          rowspan="2"
                                          id=""
                                          className=" td_slepeer"
                                        >
                                          <img
                                            src="/img/layout/hor_slepeer_avail.png"
                                            id=""
                                            className="hor-img-sleeper"
                                            alt=""
                                           
                                          />
                                        </td>
                                        <td
                                          rowspan="2"
                                          id=""
                                          className=" td_slepeer"
                                        >
                                          <img
                                            src="/img/layout/hor_slepeer_avail.png"
                                            id=""
                                            className="hor-img-sleeper"
                                            alt=""
                                         
                                          />
                                        </td>
                                      </tr>
                                      <tr></tr>
                                      <tr>
                                        <td
                                          rowspan="2"
                                          id=""
                                          className=" td_slepeer"
                                        >
                                          <img
                                            src="/img/layout/hor_slepeer_avail.png"
                                            id=""
                                            className="hor-img-sleeper"
                                            alt=""
                                          
                                          />
                                        </td>
                                        <td className=" td_slepeer"></td>
                                        <td
                                          rowspan="2"
                                          id=""
                                          className=" td_slepeer"
                                        >
                                          <img
                                            src="/img/layout/hor_slepeer_avail.png"
                                            id=""
                                            className="hor-img-sleeper"
                                            alt=""
                                         
                                          />
                                        </td>
                                        <td
                                          rowspan="2"
                                          id=""
                                          className=" td_slepeer"
                                        >
                                          <img
                                            src="/img/layout/hor_slepeer_avail.png"
                                            id=""
                                            className="hor-img-sleeper"
                                            alt=""
                                            
                                          />
                                        </td>
                                      </tr>
                                      <tr></tr>
                                      <tr>
                                        <td
                                          rowspan="2"
                                          id=""
                                          className=" td_slepeer"
                                        >
                                          <img
                                            src="/img/layout/hor_slepeer_avail.png"
                                            id=""
                                            className="hor-img-sleeper"
                                            alt=""
                                         
                                          />
                                        </td>
                                        <td className=" td_slepeer"></td>
                                        <td
                                          rowspan="2"
                                          id=""
                                          className=" td_slepeer"
                                        >
                                          <img
                                            src="/img/layout/hor_slepeer_avail.png"
                                            id=""
                                            className="hor-img-sleeper"
                                            alt=""
                                       
                                          />
                                        </td>
                                        <td
                                          rowspan="2"
                                          id=""
                                          className=" td_slepeer"
                                        >
                                          <img
                                            src="/img/layout/hor_slepeer_avail.png"
                                            id=""
                                            className="hor-img-sleeper"
                                            alt=""
                                           
                                          />
                                        </td>
                                      </tr>
                                      <tr></tr>
                                      <tr>
                                        <td
                                          rowspan="2"
                                          id=""
                                          className=" td_slepeer"
                                        >
                                          <img
                                            src="/img/layout/hor_slepeer_avail.png"
                                            id=""
                                            className="hor-img-sleeper"
                                            alt=""
                                       
                                          />
                                        </td>
                                        <td className=" td_slepeer"></td>
                                        <td
                                          rowspan="2"
                                          id=""
                                          className=" td_slepeer"
                                        >
                                          <img
                                            src="/img/layout/hor_slepeer_avail.png"
                                            id=""
                                            className="hor-img-sleeper"
                                            alt=""
                                         
                                          />
                                        </td>
                                        <td
                                          rowspan="2"
                                          id=""
                                          className=" td_slepeer"
                                        >
                                          <img
                                            src="/img/layout/hor_slepeer_avail.png"
                                            id=""
                                            className="hor-img-sleeper"
                                            alt=""
                                       
                                          />
                                        </td>
                                      </tr>
                                      <tr></tr>
                                      <tr>
                                        <td
                                          rowspan="2"
                                          id=""
                                          className=" td_slepeer"
                                        >
                                          <img
                                            src="/img/layout/hor_slepeer_avail.png"
                                            id=""
                                            className="hor-img-sleeper"
                                            alt=""
                                       
                                          />
                                        </td>
                                        <td className=" td_slepeer"></td>
                                        <td
                                          rowspan="2"
                                          id=""
                                          className=" td_slepeer"
                                        >
                                          <img
                                            src="/img/layout/hor_slepeer_avail.png"
                                            id=""
                                            className="hor-img-sleeper"
                                            alt=""
                                           
                                          />
                                        </td>
                                        <td
                                          rowspan="2"
                                          id=""
                                          className=" td_slepeer"
                                        >
                                          <img
                                            src="/img/layout/hor_slepeer_avail.png"
                                            id=""
                                            className="hor-img-sleeper"
                                            alt=""
                                          
                                          />
                                        </td>
                                      </tr>
                                      <tr></tr>
                                      <tr></tr>
                                    </tbody>
                                  </table>
                                </div>

                                <div
                                  id=""
                                  className={`top-layout tab-2  ${selectSeat === 2 ? "block" :"hidden"}`}
                                >
                                  <table
                                    id=""
                                    className="sky-tab"
                                    align="center"
                                    cellpadding=""
                                    cellspacing=""
                                    style={{width:"55%", textAlign:"center", }}
                                  >
                                    <tbody>
                                      <tr>
                                        <td className=" td_slepeer"></td>
                                        <td className=" td_slepeer"></td>
                                        <td className=" td_slepeer"></td>
                                        <td className=" td_slepeer">
                                          <span style={{height:"35px", width:"35px"}} ></span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          className=" td_slepeer"
                                          id=""
                                        >
                                          <img
                                            src="/img/layout/hor_slepeer_avail.png"
                                            id=""
                                            className="hor-img-sleeper"
                                            alt=""
                                        
                                          />
                                        </td>
                                        <td className=" td_slepeer"></td>
                                        <td
                                          className=" td_slepeer"
                                          id=""
                                        >
                                          <img
                                            src="/img/layout/hor_slepeer_avail.png"
                                            id=""
                                            className="hor-img-sleeper"
                                            alt=""
                                      
                                          />
                                        </td>
                                        <td
                                          className=" td_slepeer"
                                          id=""
                                        >
                                          <img
                                            src="/img/layout/hor_slepeer_avail.png"
                                            id=""
                                            className="hor-img-sleeper"
                                            alt=""
                                       
                                          />
                                        </td>
                                      </tr>
                                      <tr></tr>
                                      <tr>
                                        <td
                                          className=" td_slepeer"
                                          id=""
                                        >
                                          <img
                                            src="/img/layout/hor_slepeer_avail.png"
                                            id=""
                                            className="hor-img-sleeper"
                                            alt=""
                                     
                                          />
                                        </td>
                                        <td className=" td_slepeer"></td>
                                        <td
                                          className=" td_slepeer"
                                          id=""
                                        >
                                          <img
                                            src="/img/layout/hor_slepeer_avail.png"
                                            id=""
                                            className="hor-img-sleeper"
                                            alt=""
                                         
                                          />
                                        </td>
                                        <td
                                          className=" td_slepeer"
                                          id=""
                                        >
                                          <img
                                            src="/img/layout/hor_slepeer_avail.png"
                                            id=""
                                            className="hor-img-sleeper"
                                            alt=""
                                    
                                          />
                                        </td>
                                      </tr>
                                      <tr></tr>
                                      <tr>
                                        <td
                                          className=" td_slepeer"
                                          id=""
                                        >
                                          <img
                                            src="/img/layout/hor_slepeer_avail.png"
                                            id=""
                                            className="hor-img-sleeper"
                                            alt=""
                                        
                                          />
                                        </td>
                                        <td className=" td_slepeer"></td>
                                        <td
                                          className=" td_slepeer"
                                          id=""
                                        >
                                          <img
                                            src="/img/layout/hor_slepeer_avail.png"
                                            id=""
                                            className="hor-img-sleeper"
                                            alt=""
                                         
                                          />
                                        </td>
                                        <td
                                          className=" td_slepeer"
                                          id=""
                                        >
                                          <img
                                            src="/img/layout/hor_slepeer_avail.png"
                                            id=""
                                            className="hor-img-sleeper"
                                            alt=""
                                           
                                          />
                                        </td>
                                      </tr>
                                      <tr></tr>
                                      <tr>
                                        <td
                                          className=" td_slepeer"
                                          id=""
                                        >
                                          <img
                                            src="/img/layout/hor_slepeer_avail.png"
                                            id=""
                                            className="hor-img-sleeper"
                                            alt=""
                                         
                                          />
                                        </td>
                                        <td className=" td_slepeer"></td>
                                        <td
                                          className=" td_slepeer"
                                          id=""
                                        >
                                          <img
                                            src="/img/layout/hor_slepeer_avail.png"
                                            id=""
                                            className="hor-img-sleeper"
                                            alt=""
                                         
                                          />
                                        </td>
                                        <td
                                          className=" td_slepeer"
                                          id=""
                                        >
                                          <img
                                            src="/img/layout/hor_slepeer_avail.png"
                                            id=""
                                            className="hor-img-sleeper"
                                            alt=""
                                           
                                          />
                                        </td>
                                      </tr>
                                      <tr></tr>
                                      <tr>
                                        <td
                                          className=" td_slepeer"
                                          id=""
                                        >
                                          <img
                                            src="/img/layout/hor_slepeer_avail.png"
                                            id=""
                                            className="hor-img-sleeper"
                                            alt=""
                                          
                                          />
                                        </td>
                                        <td className=" td_slepeer"></td>
                                        <td
                                          className=" td_slepeer"
                                          id=""
                                        >
                                          <img
                                            src="/img/layout/hor_slepeer_avail.png"
                                            id=""
                                            className="hor-img-sleeper"
                                            alt=""
                                           
                                          />
                                        </td>
                                        <td
                                          className=" td_slepeer"
                                          id=""
                                        >
                                          <img
                                            src="/img/layout/hor_slepeer_avail.png"
                                            id="
                                            "
                                            className="hor-img-sleeper"
                                            alt=""
                                           
                                          />
                                        </td>
                                      </tr>
                                      <tr></tr>
                                      <tr></tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        {/* </div> */}

                        <div className="boarding-dropping-tab-section">
                          <div className="boarding-point-tab">
                            <div className="boarding-point-list">
                              <div className="offer-container3">
                                <div className="seat-indicator">
                                  <div className="seat01 ">
                                    <em></em>Available
                                  </div>
                                  <div className="seat01 avialable-ladies">
                                    <em></em>Avialable For Ladies
                                  </div>
                                  <div className="seat01 selected ">
                                    <em></em>Selected
                                  </div>
                                  <div className="seat01 booked">
                                    <em></em>Booked
                                  </div>
                                  <div className="seat01 booked-ladies">
                                    <em></em>Booked By Ladies
                                  </div>
                                </div>

                                <div className="text-center ">
                                  <div className="info-imp-1">
                                    The seats are indicative and not guaranteed.
                                    The bus operator reserves the right to alter
                                    them.
                                  </div>

                                  <div className="info-imp-2">
                                    Male cannot book the seat booked for ladies.
                                  </div>
                                </div>
                              </div>
                              <div className="offer-container margin-top-20px">
                                <div className="margin-bottom-10px ">
                                  <span className="sky-label">
                                    {" "}
                                    Seat Selected :{" "}
                                  </span>
                                  <span
                                    className="sky-value"
                                    id=""
                                  ></span>
                                </div>
                                <div className="sky-line mar-0"></div>
                                <div className=" margin-top-5px">
                                  <span className="sky-label sky-label-grey">
                                    {" "}
                                    Base Fare :{" "}
                                  </span>
                                  <span
                                    className="sky-sub-title-3 pull-right"
                                    id=""
                                  ></span>
                                </div>
                                <div className=" margin-top-5px">
                                  <span className="sky-label sky-label-grey">
                                    {" "}
                                    GST :{" "}
                                  </span>
                                  <span
                                    className="sky-sub-title-3 pull-right"
                                    id=""
                                  ></span>
                                </div>
                                <div className=" margin-top-5px">
                                  <span className="sky-label sky-label-grey">
                                    Operator Service Charge :{" "}
                                  </span>
                                  <span
                                    className="sky-sub-title-3 pull-right"
                                    id=""
                                  ></span>
                                </div>
                                <div className="sky-line mar-0"></div>
                                <div className=" margin-top-10px">
                                  <span className="sky-label sky-sub-title-3 ">
                                    {" "}
                                    Total Fare :{" "}
                                  </span>
                                  <span
                                    className="sky-sub-title-1 pull-right"
                                    id=""
                                  ></span>
                                </div>
                              </div>
                              <div className=" margin-top-20px">
                                <button onClick={()=>navToPassInfo()}
                                  type="button"
                                  className="btn btn-primary sky-pay"
                                  onclick="storeProceed()"
                                >
                                  Continue
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> 

          <div id="sticky-seat" className="">
            {/* <div className="inline-block  padding-left-15 pad-top4">
                        <span className="sticky-seat-label" style="color: #fff!important;">Seat(s): </span>
                        <span className="sticky-seat-label" style="color: #fff!important;" id="lblSeatNos"></span>
                    </div>
            {/* <div className="inline-block  padding-right-15 pull-right pad-top4">
                        <span className="sticky-seat-label" style="color: #fff!important;">Fare: </span>
                        <span className="sticky-seat-value" id="lblTotal" style="color: #fff!important;">0</span>
                    </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default BookingConfirmation;
