import React from "react";
import Banner from "../Banner";
import FooterImage from "../FooterImage";

const OnBusOffer = () => {
  return (
    <>
      <Banner />

      <section>
        <div
          className=" container1 mt-[50px] mb-[70px] wow fadeInDown animated animated animated"
          style={{ visibility: "visible", animationName: "fadeInDown" }}
        >
          <div
            className="offer-container-details"
            style={{
              boxShadow: "0 3px 30px 0 rgb(0 0 0 / 10%)",
              backgroundColor: "#fff",
              padding: "30px 20px 30px 20px",
              borderRadius: "10px",
              zIndex: "100",
            }}
          >
            <h1
              className="rupes-left wow fadeInDown animated animated  my-5 sky-title   animated"
              style={{
                visibility: "visible",
                animationName: "fadeInDown",
                fontFamily: "Lato, sans-serif",
                color: "rgb(192, 24, 150)",
                fontSize: "30px",
                fontWeight: "bold",
                paddingLeft: "15px",
              }}
            >
              Offer details
            </h1>

            <div className="panel-group margin-top-20px margin-top-40">
              <div className="panel panel-primary">
                <div className="panel-heading">
                  <h4 className="text-[18px]"> Offer Highlights </h4>
                </div>
                <div className="panel-body text-center">
                  <div
                    className="col-sm-6 col-md-6 col-lg-6 "
                    style={{ borderRight: "1px dashed #d5d5d5" }}
                  >
                    <p
                      className="offer-sub-title"
                      style={{
                        color: "#666666",
                        textTransform: "uppercase",
                        fontSize: "13px",
                        letterSpacing: "-0.3px",
                        lineHeight: "normal",
                        fontWeight: "bold",
                      }}
                    >
                      COUPON CODE
                    </p>
                    <p
                      className="offer-sub-details"
                      style={{
                        fontSize: "16px",
                        color: "#2f68b5",
                        textTransform: "capitalize",
                        padding: "0",
                        margin: "0",
                        lineHeight: "normal",
                      }}
                    >
                      ONBUS
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <p
                      className="offer-sub-title"
                      style={{
                        color: "#666666",
                        textTransform: "uppercase",
                        fontSize: "13px",
                        letterSpacing: "-0.3px",
                        lineHeight: "normal",
                        fontWeight: "bold",
                      }}
                    >
                      Validity
                    </p>

                    <p
                      className="offer-sub-details"
                      style={{
                        fontSize: "16px",
                        color: "#2f68b5",
                        textTransform: "capitalize",
                        padding: "0",
                        margin: "0",
                        lineHeight: "normal",
                      }}
                    >
                      30th December 2024
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="panel-group">
              <div className="panel panel-default">
                <div className="panel-heading">
                  <h4 className="text-[18px]">Terms and Conditions</h4>
                </div>

                <div className="panel-body text-[16px] text-[#333333">
                  <p>PROMO CODE: ONBUS</p>
                  <p>Save up to Rs.100 Cashback on bus ticket booking.</p>
                  <p>The offer is valid for one-time usage per user.</p>
                  <p>The minimum order value is Rs.300.</p>
                  <p>The offer is valid for all users.</p>
                  <p>
                    To avail of the offer, the user needs to have a verified
                    mobile number on BookUrSelf.
                  </p>
                  <p>Canceled orders will not be eligible for cashback.</p>
                  <p>
                    BookUrSelf reserves its absolute right to withdraw and/or
                    alter any terms and conditions of the offer at any time
                    without prior notice.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FooterImage />
    </>
  );
};

export default OnBusOffer;
