import React, { useState } from "react";
import FooterImage from "../FooterImage";

const PassengerInfo = () => {



  const [EmailId, setEmailId] = useState("")
  const [mobileNo, setmobileNo] = useState("");
  const [fullName, setFullName] = useState("");
  const [age, setAge] = useState("")
  






  const handleMobile = (e) => {
    setmobileNo(e.target.value);
  };

  const handleEmailId = (e) => {
    setEmailId(e.target.value);
  };

  const handleFullName = (e) => {
    setFullName(e.target.value);
  };

  const handleAge =(e) =>{
    setAge(e.target.value)
  }



  return (
    <>
      <section className=" margin-top-10px margin-bottom-55px">
        <div className="container1">
          <div className="offer-container-1">
            <div className="row sky-row">
              <div className="sky-sub-title">
                <span id="">Hyderabad</span>
                <i className="fa-solid fa-arrow-right-long sky-color"></i>
                <span id="">Bangalore</span>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-7">
                {/* <!-- Start Boarding & Dropping Details --> */}
                <div className="container-route ">
                  <div className="card-route">
                    <div className="row search-header text-none">
                      <div className="sky-sub-title-2 text-white ">
                        Boarding &amp; Dropping Details
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="sky-icon-field1 mar-btm0 mar-btm10">
                          <i className="fa-solid fa-location-dot fa-xl"></i>
                          <select
                            className="sky-field mar-btm10"
                            id=""
                            style={{ paddingLeft: "45px" }}
                          >
                            <option value="">Select Pickup Point</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <div className="sky-icon-field1 mar-btm0 mar-btm10">
                          <i className="fa-solid fa-location-dot fa-xl"></i>
                          <select className="sky-field mar-btm10" id="">
                            <option value="">Select Drop Point</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- End Boarding & Dropping Details --> */}

                {/* <!-- Start Contact Details --> */}
                <div className="container-route ">
                  <div className="card-route">
                    <div className="row search-header text-none">
                      <div className="sky-sub-title-2 text-white ">
                        Your ticket info will be sent here{" "}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="sky-icon-field2 mar-btm0">
                          <i className="fa-solid fa-envelope fa-xl"></i>
                          <input
                            className="sky-field mar-btm10"
                            type="text"
                            value={EmailId}
                            id="txtEmail"
                            onChange={handleEmailId}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="sky-icon-field2 mar-btm0">
                          <i className="fa-solid fa-mobile-screen-button fa-xl"></i>

                          <input
                            className="sky-field mar-btm10"
                            type="tel"
                            value={mobileNo}
                            id="txtMobNo"
                            maxLength={10}
                            onChange={handleMobile}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- End Contact Details --> */}

                {/* <!-- Start Passenger Details --> */}

                <div class="container-route ">
                  <div class="card-route">
                    <div class="row search-header text-none">
                      <div class="sky-sub-title-2 text-white ">
                        Passenger Details
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-12 col-md-12">
                        <div id="divSeatDetails" style={{ padding: "0 10px" }}>
                          <div>
                            <div
                              class="sky-icon-field3  sky-wdth2"
                            >
                            
                              <input
                                class=" "
                                style={{ height: "37px" , borderBottom:"1px solid #afadad"}}
                                type="text"
                                value="L8"
                                id=""
                                name=""
                              />
                            </div>

                            <div
                              class="sky-icon-field3  sky-wdth50"
                              style={{ width: "52%" }}
                            >
                              <i class="fa-solid fa-user fa-lg"></i>
                              <input
                                class="sky-field2 mar-btm10"
                                style={{ height: "37px" ,}}
                                type="text"
                                value={fullName}
                                id=""
                                name="txtFullName"
                                onChange={handleFullName}
                              />
                            </div>
                            <div class="sky-icon-field3  sky-wdth25">
                              <i class="fa-solid fa-signal fa-lg"></i>
                              <input
                                class="sky-field2 mar-btm10"
                                style={{ height: "37px" }}
                                type="text"
                                value={age}
                                id=""
                                name="txtAge"
                                onChange={handleAge}
                              />
                            </div>
                            <div
                              class="sky-icon-field3 sky-wdth18 sky-fm "
                              style={{ borderBottom:"1px solid #afadad" }}
                            >
                              <div style={{ display: "inline", borderBottom:"" }}>
                                <input
                                  id="rdGenderM0"
                                  name="txtGender0"
                                  type="radio"
                                  class="with-male"
                                  value="M"
                                  style={{}}
                                />
                                <label
                                  for="rdGenderM0"
                                  style={{ display: "inline", margin: "0" }}
                                ></label>
                              </div>
                              <div
                                style={{
                                  display: "inline",
                                  lineHeight: "37px",
                                }}
                              >
                                <input
                                  id="rdGenderF0"
                                  name="txtGender0"
                                  type="radio"
                                  class="with-female"
                                  value="F"
                                />
                                <label
                                  for="rdGenderF0"
                                  style={{ display: "inline", margin: "0" }}
                                ></label>
                              </div>
                            </div>
                          </div>
                          <div class="div-line"></div>
                    
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- End Passenger Details --> */}
              </div>

              {/* <!-- +++++++++++++++++++++++++++++++++++++++++ --> */}
              <div className="col-xs-12 col-sm-12 col-md-5">
                <div className="container-route ">
                  <div className="card-route">
                    <div className="row search-header text-none ">
                      <div className="sky-sub-title-2 text-white ">
                        Journey Details
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12 col-md-12">
                        <div className=" div-pad-lr-10">
                          <span className="margin-top-5px span-label">
                            Source{" "}
                          </span>{" "}
                          :{" "}
                          <span className="span-value" id="">
                            Hyderabad
                          </span>
                        </div>
                        <div className=" div-pad-lr-10">
                          <span className="margin-top-5px span-label">
                            Destination{" "}
                          </span>{" "}
                          :{" "}
                          <span className="span-value" id="">
                            Bangalore
                          </span>
                        </div>
                        <div className=" div-pad-lr-10">
                          <span className="margin-top-5px span-label">
                            Date of Journey{" "}
                          </span>{" "}
                          :{" "}
                          <span className="span-value" id="">
                            2024-04-24
                          </span>
                        </div>
                        <div className=" div-pad-lr-10">
                          <span className="margin-top-5px span-label">
                            Operator Name{" "}
                          </span>{" "}
                          :{" "}
                          <span className="span-value" id="">
                            IntrCity SmartBus
                          </span>
                        </div>
                        <div className=" div-pad-lr-10">
                          <span className="margin-top-5px span-label">
                            Seat Nos{" "}
                          </span>{" "}
                          :{" "}
                          <span className="span-value" id="">
                            L2
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container-route ">
                  <div className="card-route">
                    <div className="row search-header text-none ">
                      <div className="sky-sub-title-2 text-white ">
                        Payment Details
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12 col-md-12">
                        <div className=" margin-top-5px"></div>

                        <div className="div-pad-10 ">
                          <span className="span-label"> Base Fare </span> :
                          <span
                            className="sky-sub-title-3 pull-right"
                            id="txtBaseFare"
                          >
                            1000.00
                          </span>
                        </div>
                        <div className="div-pad-10">
                          <span className="span-label"> GST </span> :
                          <span
                            className="sky-sub-title-3 pull-right"
                            id="txtStax"
                          >
                            50.00
                          </span>
                        </div>
                        <div className=" div-pad-10 ">
                          <span className="span-label">Service Charge </span> :
                          <span
                            className="sky-sub-title-3 pull-right"
                            id="txtOtax"
                          >
                            0.00
                          </span>
                        </div>

                        <div className="sky-line mar-0"></div>
                        <div className=" margin-top-5px"></div>
                        <div className=" div-pad-lr-10 margin-top-5px">
                          <span className="span-label sky-sub-title-3 ">
                            {" "}
                            Total Fare{" "}
                          </span>{" "}
                          :
                          <span
                            className="sky-sub-title-1 pull-right"
                            id="txtTotalFare"
                          >
                            1050.00
                          </span>
                        </div>

                        <div className="div-pad-10">
                          <span className="span-label">Promo Code</span> :
                          <input
                            className="border-bottom text-center offer-label"
                            type="text"
                            id="promoCode"
                            name="promoCode"
                          />
                          <button
                            className="btn-promo-apply  px-4 ms-2"
                            style={{
                              border: "2px solid black",
                              fontSize: "16px",
                            }}
                            type="button"
                          >
                            Apply
                          </button>
                          <span>
                            {" "}
                            <i className="fa-solid fa-trash-can btn-promo-remove "></i>{" "}
                          </span>
                        </div>

                        <div id="divPromoCode" className="display-none">
                          <div className="div-pad-10">
                            <span className="span-label">Discount Amount </span>{" "}
                            :{" "}
                            <span
                              className="span-value pull-right"
                              id="idDiscountAmount"
                            >
                              {" "}
                            </span>
                          </div>
                        </div>

                        <div id="divWalletAmount" className="display-none">
                          <div className="div-pad-10">
                            <span className="span-label">Wallet Amount </span> :{" "}
                            <span
                              className="span-value pull-right"
                              id="idWalletAmount"
                            >
                              {" "}
                            </span>
                          </div>
                        </div>

                        <div className="div-pad-10">
                          <span className="span-label">Net Payable </span> :{" "}
                          <span
                            className="span-value pull-right"
                            id="idFinalAmount"
                          >
                            1050.00
                          </span>
                        </div>
                      </div>
                    </div>

                    <button
                      type="button"
                      className="btn btn-primary sky-pay btn-block-continue pull-right"
                    >
                      <span style={{ fontSize: "16px" }}> Proceed to Pay </span>
                      <span style={{ fontSize: "20px" }}>
                        (
                        <span style={{ fontSize: "20px" }} id="">
                          1050.00
                        </span>{" "}
                        )
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterImage/>

    </>
  );
};

export default PassengerInfo;
