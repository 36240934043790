import React from 'react'

const FooterImage = () => {
  return (
    <>
    
    <div className="footer-image" style={{background:"url('/images/road_grey.png')",position:"relative",height:"75px",top:"1px",backgroundSize:"100% 100%",backgroundPosition:"bottom",marginTop:"30px",zIndex:""}}> 
    <div>
        <img className="footer-bus" src="/images/bus-image.png" style={{zIndex:"",position:"absolute",top:"-10px",left:"20px"}} alt="Bookurself: Online bus ticket booking, book bus" title="Bookurself: Online bus ticket booking, book bus"/>
    </div>
</div>
    
    </>
  )
}

export default FooterImage


