import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp} from '@fortawesome/free-solid-svg-icons'

const GoTopBtn = () => {

    const [isVisible,setIsVisible]=useState(false)

    useEffect(() => {
      
        window.addEventListener('scroll',listenToScroll);

    }, [])

    const listenToScroll =()=>{
        let heightToHidden = 100;
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

        if(winScroll>heightToHidden){
            setIsVisible(true)
        }
        else{
            setIsVisible(false)
        }
    }
    

 const  goToBtn = ()=>{
    window.scrollTo({top:0, left:0,behavior:"smooth"})
 }



  return (
    
    <>

    {isVisible && (
    
    <div className='w-[50px] h-[60px] rounded-full flex justify-center items-center fixed bottom-[80px] right-[20px]'  style={{backgroundColor:"red"}} onClick={goToBtn}>

    <FontAwesomeIcon icon={faArrowUp} style={{color:"white",fontSize:"18px"}} />

    </div>
    )}

    </>
  )
}

export default GoTopBtn