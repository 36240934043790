
import { Link } from "react-router-dom";
import ".//../Components css/Footer.css";

const Footer = () => {


  const links = [
    {
      id: 0,
      name: "About Us",
      link: "/about",
    },

    {
      id: 1,
      name: "Contact Us",
      link: "/contact",
    },
    {
      id: 2,
      name: "Mobile App",
      link:"https://play.google.com/store/apps/details?id=com.infysky.bookurself&hl=en&pli=1"
    },
    {
      id: 3,
      name: "Offers",
      link: "/offers",
    },
  ];

  const links1 = [
    {
      id: 0,
      name: "T & C",
      link: "/terms",
    },
    {
      id: 1,
      name: "Privacy Policy",
      link:"/privacy"
    },
    {
      id: 2,
      name: "FAQ",
      link: "/faq",
    },
  ];
  return (
    <>
      
      <div className=" " style={{backgroundColor:"#800080",paddingTop:"25px"}}>
        <div className=" container1  bg-[#800080] ">
          <div className="row " style={{color:"#fff",textAlign:"center",marginLeft:"15px",marginRight:"15px"}}>
            <div className="col-xs-12 col-sm-3 col-md-4 col-lg-4 " >
              <div className=" text-white text-center" >
                <p className="font-bold " style={{fontSize:"20px",paddingTop:"10px",}}>
                 <b>BookUrSelf</b> 
                </p>
                <br />

                {links.map((item, index) => (
                  <>
                    <Link style={{textDecoration:"none",color:"#fff",fontSize:"16px"}}
                      to={item.link}
                      key={item.id}
                      className=""
                    >
                      {item.name}
                    </Link>

                    <br />
                  </>
                ))}
              </div>
            </div>

            <div className=" col-xs-12 col-sm-3 col-md-4  col-lg-3 " >
              <div className=" text-[#fff] text-center">
                <p className="font-bold text-[22px] pt-[10px]" style={{fontSize:"22px",paddingTop:"10px"}}><b>Information</b></p>
                <br />

                {links1.map((item, index) => (   
                  <>
                    <Link 
                    style={{textDecoration:"none",color:"#fff",fontSize:"16px"}}
                      to={item.link}
                      key={item.id}
                      className=""
                    >
                      {item.name}
                    </Link>
                    <br />
                  </>
                ))}

                <br />
              </div>
            </div>


            <div className="col-xs-12 col-sm-5  col-md-4  col-lg-4 " >
                <div className="widget widgets-company-info" data-aos="fade-down" >
                    <div className="img-cont flex justify-center" style={{paddingTop:"10px"}}>
                        <a href="/">
                            <img src="/images/logo3.png" alt=""  style={{height:"50px"}}/>
                        </a>
                    </div>
                    <div className="footer-social-info" style={{paddingBottom:"10px"}} >
                        <p className="text-[16px]" style={{textAlign:"center"}}>
                            <b>BookUrSelf</b> is the India's Fastest Emerging and Trusted online bus ticket booking
                            service. <b>BookUrSelf</b> offers bus ticket booking through its website and Android mobile
                            apps for all major route
                        </p>
                    </div>
                    <div className="footer-social-info" style={{paddingTop:"20px"}} ></div>
                    <div className="footer-social-info"></div>
                </div>
            </div>
          </div>

          <div className="row footer-bottom mt-70 pt-3 pb-1 " style={{padding:"20px 0 20px 0",textAlign:"center"}} >
            <div className="col-lg-12 col-md-12">
                <div className="footer-bottom-content">
                    <div className="footer-bottom-content-copy">
                        <p className="text-[#fff] text-[16px]">© 2023 Bookurself. All Rights Reserved. Developed with <span  style={{color:"#ba0001",fontSize:"22px"}} className="sky-heart">♥</span> by <a href="http://www.infysky.com">
                                <strong  style={{color:"#2980b9"}}>Infy SKY</strong>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        </div>
      </div>


    </>
  );
};

export default Footer;
