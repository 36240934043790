import React from 'react'
import { LoginSideBar } from './ProfilePage'

const MyBookings = () => {

  return (

    <>
    
    
    <section className="mt-[20px] mb-[55px]">
        <div
          className="container1"
          style={{
            boxShadow: "0 3px 30px 0 rgba(0 0 0 /10%)",
            backgroundColor: "#fff",
            padding: "10px 10px 30px 10px",
            borderRadius: "10px",
          }}
        >
          <div
            className="chip "
            style={{
              display: "inline-block",
              padding: "0 25px",
              height: "50px",
              fontSize: "16px",
              lineHeight: "50px",
              borderRadius: "25px",
              backgroundColor: "#f1f1f1",
              border: "1px solid #ccc",
              marginBottom: "10px",
            }}
          >
            <img
              className=""
              src="/images/user-icon-01.gif"
              alt="User"
              width="96"
              height="96"
              style={{
                float: "left",
                margin: "0 10px 0 -25px",
                height: "49px",
                width: "50px",
                borderRadius: "50%",
                border: "1px solid #ccc",
              }}
            />
            Cust Name
          </div>



          <div className="  ">

        <LoginSideBar></LoginSideBar>



            <div style={{ minHeight: "360px", padding: "1px 16px" }} className="min-[601px]:ms-[200px] ">

              <div className=" " style={{ boxShadow: "0 3px 30px 0 rgb(0 0 0 /10%)", backgroundColor: "#fff", padding: "10px 10px 30px 10px", borderRadius: "10px", zIndex: "" }}>
            
              <div className="row">


                            <div className="book-card" style={{boxShadow:"0 3px 30px 0 rgb(0 0 0 / 10%)", backgroundColor:"#fff", margin:"10px" , padding:"10px", borderRadius:"10px", }}>

                                <h1 className='text-[36px]'>Currently there are no bookings</h1>

                            </div>
                                 
                    </div>

              </div>
            </div>



          </div>
        </div>
      </section>


    </>
  )
}

export default MyBookings